import { v4 as uuidv4 } from 'uuid';
import { userStore } from '../services/store.js';
import type { Serializable } from '../util/serializable.js';
import { StringProperty } from './property.js';
import { get } from "svelte/store";
import { project } from "../services/app.js";
import api from '../api/api.js';
import { EaseCurves } from '../api/animationApi.js';
import Vector3Api from '../api/vector3Api.js';
import QuaternionApi from '../api/quaternionApi.js';

let data = {};

const AsyncFunction = Object.getPrototypeOf(async function(){}).constructor;

const sampleScript = `// This is a sample script. Feel free to delete everything here.
// Assign this script to run from an action such as the scene's
// 'On Loaded' or an object's 'On Clicked' event in the editor.

// You can store state between scripts by using the 'data' global.
data.counter = data.counter + 1 || 1;

// You can log debug messages to the console.
// Hit 'F12' in to bring up your browser's console and see these messages.
console.log(\`The script has been called \${data.counter} times!\`);

// Use the 'target' global to modify the object which triggered this script.
// Here we animate the target to get bigger.
target?.animateTo({
    scale: target.getScale() * 1.1,
    time: 0.5,
    easing: EaseCurves.EaseInOutQuintic
});

// You can also find other objects in the scene and modify them.
const objectToHide = scene.getObjectByName('ObjectToHide');
objectToHide?.setEnabled(false);
`;

export class Script implements Serializable {
    public id = new StringProperty(uuidv4());
    public filename = new StringProperty();
    public name = new StringProperty();
    public code = new StringProperty(sampleScript);

    private fn: Function;

    constructor(projectId: string) {
        this.refreshFilename(projectId);
    }

    public invoke(target: any, args: string) {
        if (!this.fn) {
            this.fn = new AsyncFunction(
                'scene', 'data', 'target', 'args', 'EaseCurves', 'Vector3', 'Quaternion',
                this.code.get());
        }

        this.fn(api.get(get(get(project).scene)), data, api.get(target), args, EaseCurves, Vector3Api, QuaternionApi);
    }

    public async save() {
        const data = JSON.stringify({
            name: this.name.get(),
            code: this.code.get()
        });

        await userStore.save(new Blob([data]), this.filename.get());
    }

    public async load() {
        try {
            console.log("Loading script: " + this.filename.get());
            const json = await userStore.load(this.filename.get());
            const data = await JSON.parse(await json.text());
            this.name.set(data.name);
            this.code.set(data.code);
            console.log("Finished loading script: " + this.filename.get());
        } catch (e) {
            console.error("Failed to load script: " + this.filename.get());
            throw e;
        }
    }

    public async delete() {
        await userStore.delete(this.filename.get());
    }

    public refreshFilename(projectId: string) {
        this.filename.set(`script-${projectId}-${this.id.get()}.json`);
    }

    serialize() {
        return {
            id: this.id.get(),
            filename: this.filename.get()
        }
    }

    deserialize(data: any) {
        this.id.set(data.id);
        this.filename.set(data.filename);
    }
}