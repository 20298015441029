import type * as BABYLON from '@babylonjs/core';
import { get, Readable, Unsubscriber } from "svelte/store";

export default class Subscriptions {
    private unsubscribers: Unsubscriber[] = [];
    public get count() { return this.unsubscribers.length; }

    public dispose() {
        for (const unsubscriber of this.unsubscribers) {
            unsubscriber();
        }

        this.unsubscribers = [];
    }

    public add(unsubscriber: Unsubscriber) {
        this.unsubscribers.push(unsubscriber);
    }

    public on<T>(observable: BABYLON.Observable<T>, cb: (t: T) => void) {
        const observer = observable.add(cb);
        this.unsubscribers.push(() => observable.remove(observer));
    }

    public subscribe<T>(store: Readable<T>, cb: (t: T) => void) {
        this.unsubscribers.push(store.subscribe(cb));
    }

    public subscribeUntilValid<T>(store: Readable<T>, cb: (t: T) => void) {
        const v = get(store);
        if (v) {
            cb(v);
        } else {
            const unsub = store.subscribe((v) => {
                if (v) {
                    unsub();
                    cb(v);
                }
            });
        }
    }
}