import { AnimationType } from "./animation";

function map(data: any, key: string, func: (value: any) => any) {
    if (Array.isArray(data)) {
        for (const item of data) {
            map(item, key, func);
        }
    } else if (data && typeof data === 'object') {
        for (const [k, value] of Object.entries(data)) {
            if (key === k) {
                data[key] = func(value);
            }

            map(value, key, func);
        }
    }
}

export namespace compat {
    export function upgrade(data: any) {
        const fromVersion = data.version;

        // Unversioned -> 0.1
        if (!fromVersion) {
            map(data, 'model', (src) => ({ userAsset: false, src: src }));
            map(data, 'image', (src) => ({ userAsset: false, src: src }));
        }

        if (fromVersion < 0.2) {
            console.log("Upgrading to version 0.2");
            for (const scene of data.scenes) {
                scene.backgroundType = 0;
                scene.background = {
                    skybox: scene.skybox
                }

                delete(scene.skybox);
            }

            map(data, 'model', (model) => ({
                name: 'Model',
                url: model.src,
                userAsset: model.userAsset,
            }));

            map(data, 'image', (image) => ({
                name: 'Image',
                url: image.src,
                userAsset: image.userAsset,
            }));
        }

        if (fromVersion < 0.3) {
            console.log("Upgrading to version 0.3");
            map(data, 'environment', () => 1);
        }

        if (fromVersion < 0.4) {
            console.log("Upgrading to version 0.4");
            const oldSkyboxes = [
                'SwedishRoyalCastle',
                'HornstullsStrand',
                'NightPath',
                'PondNight',
                'Powerlines'
            ];

            const newSkyboxes = [
                'BlueSky',
                'BlueSunset',
                'ColdNight',
                'ColdSunset',
                'DeepDusk',
                'GloriousPink',
                'HornstullsStrand',
                'NightMoonBurst',
                'NightPath',
                'NightSky',
                'OvercastLow',
                'PondNight',
                'Powerlines',
                'SpaceAnotherPlanet',
                'SwedishRoyalCastle',
            ];

            map(data, 'skybox', (x: number) => newSkyboxes.indexOf(oldSkyboxes[x]))
        }

        if (fromVersion < 0.5) {
            console.log("Upgrading to version 0.5");
            map(data, 'type', (x: string) => x === 'backplate' ? 'rect' : x);
        }

        if (fromVersion < 0.6) {
            console.log("Upgrading to version 0.6");
            map(data, 'sound', (audio) => {
                audio.playOnStart = true;
                return audio;
            });
        }

        if (fromVersion < 0.7) {
            console.log("Upgrading to version 0.7");
            map(data, 'model', model => ({
                name: model.name,
                url: model.url,
                provider: model.userAsset ? 'user' : 'media',
            }));

            map(data, 'image', image => ({
                name: image.name,
                url: image.url,
                provider: image.userAsset ? 'user' : 'media',
            }));

            map(data, 'backgroundPhoto', image => ({
                name: image.name,
                url: image.url,
                provider: image.userAsset ? 'user' : 'media',
            }));

            // Sound
            map(data, 'source', source => (source ? {
                name: source.name,
                url: source.url,
                provider: source.userAsset ? 'user' : 'media',
            } : null));
        }

        if (fromVersion < 0.8) {
            console.log("Upgrading to version 0.8");

            const oldGrounds = [
                'clovers',
                'cracks',
                'desert-cracks',
                'desert-mountain',
                'desert-sand',
                'desert-sand-2',
                'desert-sand-3',
                'desert-sand-4',
                'desert-stone',
                'dry-ground',
                'ferns',
                'grass',
                'grass-autumn-orange',
                'grass-autumn-red',
                'lava',
                'lava-2',
                'savanna',
                'snow',
                'snow-grass',
            ];

            const newGrounds = [
                'brick',
                'clovers',
                'concrete',
                'cracks',
                'desert-cracks',
                'desert-mountain',
                'desert-sand',
                'desert-sand-2',
                'desert-sand-3',
                'desert-sand-4',
                'desert-stone',
                'dry-ground',
                'ferns',
                'grass',
                'grass-autumn-orange',
                'grass-autumn-red',
                'lava',
                'lava-2',
                'plastic',
                'savanna',
                'snow',
                'snow-grass',
                'wood',
            ];

            map(data, 'material', x => x === 'bricks' ? 'brick' : x);

            map(data, 'ground', (x: number) => newGrounds.indexOf(oldGrounds[x]));

            data.scripts = data.scripts || [];
            data.scripts = data.scripts.map((s: string) => { return {
                id: s,
                filename: `script-${s}`
            }});
        }

        if (fromVersion < 0.9) {
            console.log("Upgrading to version 0.9");

            const nodes = data.scenes.flatMap((s: any) => s.nodes);
            for (const node of nodes) {
                if (node.animation?.animation && node.animation.animation !== 'None') {
                    node.animation = {
                        animationType: Object.values(AnimationType).indexOf(AnimationType.FromModel),
                        animation: {
                            animation: node.animation.animation,
                            loop: node.animation.loop
                        }
                    };
                } else {
                    node.animation = {
                        animationType: node.motion?.motionType || 0,
                        animation: node.motion?.motion || null
                    };
                }
            }

            for (const action of data.actions) {
                for (const step of action.steps) {
                    if (step.type === 'play-animation') {
                        step.type = 'set-animation';
                        step.object = step.model;
                        step.animation = {
                            animationType: Object.values(AnimationType).indexOf(AnimationType.FromModel),
                            animation: {
                                animation: step.animation.animation,
                                loop: step.animation.loop
                            }
                        }
                    } else if (step.type === 'stop-animation') {
                        step.type = 'set-animation';
                        step.object = step.model;
                        step.animation = {
                            animationType: 0
                        }
                    } else if (step.type === 'set-motion') {
                        step.type = 'set-animation';
                        step.animation = {
                            animationType: step.motion.motionType || 0,
                            animation: step.motion?.motion || null,
                        }
                    }
                }
            }
        }

        console.log("Upgrade Complete.");

        return data;
    }
}