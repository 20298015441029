import * as BABYLON from '@babylonjs/core';
import type RectNode from '../models/rectNode.js';
import ObjectApi from './objectApi.js';

/** Represents a rect object in the scene. */
export default class RectApi extends ObjectApi {
    constructor(private rectNode: RectNode) {
        super(rectNode);
    }

    /** Returns the width of this rect in meters. */
    public getWidth(): number {
        return this.rectNode.width.get();
    }

    /** Sets the width of this rect in meters. */
    public setWidth(width: number) {
        this.rectNode.width.set(width);
    }

    /** Returns the height of this rect in meters. */
    public getHeight(): number {
        return this.rectNode.height.get();
    }

    /** Sets the height of this rect in meters. */
    public setHeight(height: number) {
        this.rectNode.height.set(height);
    }

    /** Returns the background color of this rect in hex format. */
    public getBackgroundColor(): string {
        return this.rectNode.backgroundColor.get().toHexString();
    }

    /** Sets the background color of this rect in hex format. */
    public setBackgroundColor(color: string) {
        this.rectNode.backgroundColor.set(BABYLON.Color3.FromHexString(color));
    }

    /** Returns the border color of this rect in hex format. */
    public getBorderColor(): string {
        return this.rectNode.borderColor.get().toHexString();
    }

    /** Sets the border color of this rect in hex format. */
    public setBorderColor(color: string) {
        this.rectNode.borderColor.set(BABYLON.Color3.FromHexString(color));
    }

    /** Sets the border radius of this rect in radians. */
    public getBorderRadius(): number {
        return this.rectNode.borderRadius.get();
    }

    /** Returns the border radius of this rect in radians. */
    public setBorderRadius(borderRadius: number) {
        this.rectNode.borderRadius.set(borderRadius);
    }

    /** Returns the border thickness of this rect in meters. */
    public getBorderThickness(): number {
        return this.rectNode.borderThickness.get();
    }

    /** Sets the border thickness of this rect in meters. */
    public setBorderThickness(borderThickness: number) {
        this.rectNode.borderThickness.set(borderThickness);
    }
}