<script lang="ts">
    export let showText = true;
</script>

<div class="flex align-center">
    <img src="logo.svg" alt="logo" />
    {#if showText}
        <div class="logo">VIRTUAL MAKER</div>
    {/if}
</div>

<style>
    .logo {
        font-family: 'Poppins', sans-serif;
        font-size: 1.5rem;
        white-space: nowrap;
    }

    img {
        height: 2.5rem;
        margin-right: 0.4em;
        object-fit: cover;
    }
</style>