import type { Vector3, Quaternion } from '../models/property.js';

type EaseCurve = [number, number, number, number];

/*
 * Predefined ease curves from https://easings.net/. Curve shapes can be previewed there.
 * Create your own at http://cubic-bezier.com.
 * Note: Curves that end with 'Back' include some overshoot.
 *
 * Linear, Step, EaseInSine, EaseInQuadratic, EaseInCubic, EaseInQuartic, EaseInQuintic,
 * EaseInExponential, EaseInCircular, EaseInBack, EaseOutSine, EaseOutQuadratic,
 * EaseOutCubic, EaseOutQuartic, EaseOutQuintic, EaseOutExponential, EaseOutCircular,
 * EaseOutBack, EaseInOutSine, EaseInOutQuadratic, EaseInOutCubic, EaseInOutQuartic,
 * EaseInOutQuintic, EaseInOutExponential, EaseInOutCircular, EaseInOutBack,
 */
 export const EaseCurves = {
	Linear: [0, 0, 1, 1] as EaseCurve,
	Step: [0, 0, 0, 0] as EaseCurve,
	EaseInSine: [0.47, 0, 0.745, 0.715] as EaseCurve,
	EaseInQuadratic: [0.55, 0.085, 0.68, 0.53] as EaseCurve,
	EaseInCubic: [0.55, 0.055, 0.675, 0.19] as EaseCurve,
	EaseInQuartic: [0.895, 0.03, 0.685, 0.22] as EaseCurve,
	EaseInQuintic: [0.755, 0.05, 0.855, 0.06] as EaseCurve,
	EaseInExponential: [0.95, 0.05, 0.795, 0.035] as EaseCurve,
	EaseInCircular: [0.6, 0.04, 0.98, 0.335] as EaseCurve,
	EaseInBack: [0.6, -0.28, 0.735, 0.045] as EaseCurve,
	EaseOutSine: [0.39, 0.575, 0.565, 1] as EaseCurve,
	EaseOutQuadratic: [0.25, 0.46, 0.45, 0.94] as EaseCurve,
	EaseOutCubic: [0.215, 0.61, 0.355, 1] as EaseCurve,
	EaseOutQuartic: [0.165, 0.84, 0.44, 1] as EaseCurve,
	EaseOutQuintic: [0.23, 1, 0.32, 1] as EaseCurve,
	EaseOutExponential: [0.19, 1, 0.22, 1] as EaseCurve,
	EaseOutCircular: [0.075, 0.82, 0.165, 1] as EaseCurve,
	EaseOutBack: [0.175, 0.885, 0.32, 1.275] as EaseCurve,
	EaseInOutSine: [0.445, 0.05, 0.55, 0.95] as EaseCurve,
	EaseInOutQuadratic: [0.455, 0.03, 0.515, 0.955] as EaseCurve,
	EaseInOutCubic: [0.645, 0.045, 0.355, 1] as EaseCurve,
	EaseInOutQuartic: [0.77, 0, 0.175, 1] as EaseCurve,
	EaseInOutQuintic: [0.86, 0, 0.07, 1] as EaseCurve,
	EaseInOutExponential: [1, 0, 0, 1] as EaseCurve,
	EaseInOutCircular: [0.785, 0.135, 0.15, 0.86] as EaseCurve,
	EaseInOutBack: [0.68, -0.55, 0.265, 1.55] as EaseCurve,
};

/**
 * Determines the value that each object property should be after a specified time,
 * following an optional easing curve. Unspecified properties are not changed.
 * The default easing is Linear.
 */
export class Keyframe {
	/** X (horizontal) position in local space */
    x?: number;
	/** Y (vertical) position in local space */
    y?: number;
	/** Z (depth) position in local space */
    z?: number;
	/** Position in local space */
	position?: Vector3;
	/** X (horizontal) axis rotation in local space */
    rotationX?: number;
	/** Y (vertical) axis rotation in local space */
    rotationY?: number;
	/** Z (depth) axis rotation in local space */
    rotationZ?: number;
	/** Quaternion rotation in local space */
	quaternion?: Quaternion;
	/** Uniform scale rotation in local space */
    scale?: number;
	/** Time to apply keyframe in seconds */
    time: number;
	/** Bezier curve shape. See EaseCurves for options.  */
    easing?: [number, number, number, number];
}

/**
 * Determines the value that each material property should be after a specified time,
 * following an optional easing curve. Unspecified properties are not changed.
 * The default easing is Linear.
 */
export class MaterialKeyframe {
	/** Base color in hex format */
	color?: string;
	/** Roughness factor */
	roughnessFactor?: number;
	/** Metallic factor */
	metallicFactor?: number;
	/** Emissive factor in hex format */
	emissiveFactor?: string;
	/** Time to apply keyframe in seconds */
	time: number;
	/** Bezier curve shape. See EaseCurves for options.  */
    easing?: [number, number, number, number];
}