import ObjectNode from "./objectNode";
import { ActionProperty, BoolProperty } from "./property";

export default abstract class InteractableNode extends ObjectNode {
    public clickEnabled = new BoolProperty(true, '/docs?p=clicking');
    public onClicked = new ActionProperty(this.id, this, 'button-clicked', '', '/docs?p=clicking');
    public grabEnabled = new BoolProperty(false, '/docs?p=grabbing');
    public onGrabbed = new ActionProperty(this.id, this, 'grabbed', 'Requires: Grab Enabled.', '/docs?p=grabbing');
    public onReleased = new ActionProperty(this.id, this, 'released', 'Requires: Grab Enabled.', '/docs?p=grabbing');

    public get inspectorProperties() {
        return [...super.inspectorProperties, 'onClicked', 'grabEnabled', 'onGrabbed', 'onReleased']
    };
}