import * as BABYLON from '@babylonjs/core';
import { EaseCurves, MaterialKeyframe } from './animationApi.js';

/** Represents a material on a model object. */
export default class MaterialApi {
    constructor(private material: BABYLON.PBRMaterial) {};

    /** Returns the base color of this material in hex format. */
    public getColor(): string {
        return this.material.albedoColor.toHexString();
    }

    /** Sets the base color of this material in hex format. */
    public setColor(color: string) {
        this.material.albedoColor = BABYLON.Color3.FromHexString(color);
    }

    /** Returns the roughness factor of this material. */
    public getRoughnessFactor(): number {
        return this.material.roughness;
    }

    /** Sets the roughness factor of this material. */
    public setRoughnessFactor(value: number) {
        this.material.roughness = value;
    }

    /** Returns the metallic factor of this material. */
    public getMetallicFactor(): number {
        return this.material.metallic;
    }

    /** Sets the roughness factor of this material. */
    public setMetallicFactor(value: number) {
        this.material.metallic = value;
    }

    /** Returns the emissive factor of this material in hex format. */
    public getEmissiveFactor(): string {
        return this.material.emissiveColor.toHexString();
    }

    /** Sets the emissive factor of this material in hex format. */
    public setEmissiveFactor(color: string) {
        this.material.emissiveColor = BABYLON.Color3.FromHexString(color);
    }

    /** Returns the alpha mode of this material. */
    public getAlphaMode(): 'OPAQUE' | 'MASK' | 'BLEND'  {
        switch (this.material.transparencyMode) {
            case BABYLON.PBRMaterial.PBRMATERIAL_OPAQUE: return 'OPAQUE';
            case BABYLON.PBRMaterial.PBRMATERIAL_ALPHATEST: return 'MASK';
            default: return 'BLEND';
        }
    }

    /** Sets the alpha mode of this material. */
    public setAlphaMode(value: 'OPAQUE' | 'MASK' | 'BLEND') {
        switch (value) {
            case 'OPAQUE': {
                this.material.transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_OPAQUE;
                if (this.material.albedoTexture) {
                    this.material.albedoTexture.hasAlpha = false;
                    this.material.useAlphaFromAlbedoTexture = false;
                }
                break;
            }
            case 'MASK': {
                this.material.transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHATEST;
                if (this.material.albedoTexture) {
                    this.material.albedoTexture.hasAlpha = true;
                    this.material.useAlphaFromAlbedoTexture = false;
                }
                break;
            }
            case 'BLEND': {
                this.material.transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND;
                if (this.material.albedoTexture) {
                    this.material.albedoTexture.hasAlpha = true;
                    this.material.useAlphaFromAlbedoTexture = true;
                }
                break;
            }
        }
    }

    /** Returns the alpha cutoff value of this material. */
    public getAlphaCutoff(): number {
        return this.material.alphaCutOff;
    }

    /** Sets the alpha cutoff value of this material. */
    public setAlphaCutoff(value: number) {
        this.material.alphaCutOff = value;
    }

    /** Returns if this material will display double sided. */
    public getDoubleSided(): boolean {
        return !this.material.backFaceCulling;
    }

    /** Sets if this material will display double sided. */
    public setDoubleSided(value: boolean) {
        this.material.backFaceCulling = !value;
        this.material.twoSidedLighting = value;
    }

    private _animateTo(kf: MaterialKeyframe) {
        let grp = new BABYLON.AnimationGroup('script-animation');
        kf.easing = kf.easing || EaseCurves.Linear;
        const easingFunc = new BABYLON.BezierCurveEase(kf.easing[0], kf.easing[1], kf.easing[2], kf.easing[3]);
        const framesPerSecond = 30;
        const endTime = framesPerSecond * kf.time;

        const addAnimation = (prop: string, startValue: any, endValue: any, type: number) => {
            const anim = new BABYLON.Animation('script-animation-' + prop, prop, framesPerSecond, type, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
            anim.setKeys([
                { frame: 0, value: startValue },
                { frame: endTime, value: endValue }
            ]);
            anim.setEasingFunction(easingFunc);
            grp.addTargetedAnimation(anim, this.material);
        };

        if (kf.color) {
            const endValue = BABYLON.Color3.FromHexString(kf.color)
            addAnimation('albedoColor', this.material.albedoColor, endValue, BABYLON.Animation.ANIMATIONTYPE_COLOR3);
        }

        if (kf.emissiveFactor) {
            const endValue = BABYLON.Color3.FromHexString(kf.emissiveFactor)
            addAnimation('emissiveColor', this.material.emissiveColor, endValue, BABYLON.Animation.ANIMATIONTYPE_COLOR3);
        }

        if (kf.roughnessFactor) {
            addAnimation('roughness', this.material.roughness, kf.roughnessFactor, BABYLON.Animation.ANIMATIONTYPE_FLOAT);
        }

        if (kf.metallicFactor) {
            addAnimation('metallic', this.material.metallic, kf.metallicFactor, BABYLON.Animation.ANIMATIONTYPE_FLOAT);
        }

        const promise = new Promise<void>(resolve => {
            grp.onAnimationEndObservable.addOnce(() => resolve());
        });

        grp.start();
        return promise;
    }

    /** Animates this material from its current values to each passed in Keyframe in sequence. */
    public async animateTo(...keyframes: MaterialKeyframe[]) {
        for (const kf of keyframes) {
            await this._animateTo(kf)
        }
    }
}
