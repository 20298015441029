import type { Vector3 } from "../models/property";

/** Representation of 3D vectors and points. */
export default class Vector3Api {

    /** Creates a new Vector3 */
    public static Create(x = 0, y = 0, z = 0): Vector3 {
        return { x, y, z };
    }

    /** Creates a new copy of a Vector3 */
    public static Copy(v: Vector3): Vector3 {
        return { x: v.x, y: v.y, z: v.z };
    }

    /** Copies the values of v2 into v1 and returns v1. */
    public static CopyInPlace(v1: Vector3, v2: Vector3): Vector3 {
        v1.x = v2.x;
        v1.y = v2.y;
        v1.z = v2.z;
        return v1;
    }

    /** When normalized, a vector keeps the same direction but its length is 1.0. */
    public static Normalize(v: Vector3): Vector3 {
        const l = Vector3Api.Length(v);
        return { x: v.x / l, y: v.y / l, z: v.z / l };
    }

    /** When normalized, a vector keeps the same direction but its length is 1.0. */
    public static NormalizeInPlace(v: Vector3): Vector3 {
        const l = Vector3Api.Length(v);
        v.x /= l;
        v.y /= l;
        v.z /= l;
        return v;
    }

    /** Returns the length of the vector. */
    public static Length(v: Vector3): number {
        return Math.sqrt(Vector3Api.LengthSquared(v));
    }

    /** Returns the square of the length of the vector. */
    public static LengthSquared(v: Vector3): number {
        return v.x * v.x + v.y * v.y + v.z * v.z;
    }

    /** Multiplies each component of the vector by the scale factor */
    public static Scale(v: Vector3, factor: number): Vector3 {
        return { x: v.x * factor, y: v.y * factor, z: v.z * factor };
    }

    /** Multiplies each component of the vector by the scale factor */
    public static ScaleInPlace(v: Vector3, factor: number): Vector3 {
        v.x *= factor;
        v.y *= factor;
        v.z *= factor;
        return v;
    }

    /** Adds each component of the vectors. */
    public static Add(v1: Vector3, v2: Vector3): Vector3 {
        return { x: v1.x + v2.x, y: v1.y + v2.y, z: v1.z + v2.z };
    }

    /** Adds each component of v2 to v1 and returns v1. */
    public static AddInPlace(v1: Vector3, v2: Vector3): Vector3 {
        v1.x += v2.x;
        v1.y += v2.y;
        v1.z += v2.z;
        return v1;
    }

    /** Subtracts each component of the vectors. */
    public static Subtract(v1: Vector3, v2: Vector3): Vector3 {
        return { x: v1.x - v2.x, y: v1.y - v2.y, z: v1.z - v2.z };
    }

    /** Subtracts each component of v2 to v1 and returns v1. */
    public static SubtractInPlace(v1: Vector3, v2: Vector3): Vector3 {
        v1.x -= v2.x;
        v1.y -= v2.y;
        v1.z -= v2.z;
        return v1;
    }

    /** Multiplies each component of the vectors. */
    public static Multiply(v1: Vector3, v2: Vector3): Vector3 {
        return { x: v1.x * v2.x, y: v1.y * v2.y, z: v1.z * v2.z };
    }

    /** Multiplies each component of v2 to v1 and returns v1. */
    public static MultiplyInPlace(v1: Vector3, v2: Vector3): Vector3 {
        v1.x *= v2.x;
        v1.y *= v2.y;
        v1.z *= v2.z;
        return v1;
    }

    /** Divides each component of the vectors. */
    public static Divide(v1: Vector3, v2: Vector3): Vector3 {
        return { x: v1.x / v2.x, y: v1.y / v2.y, z: v1.z / v2.z };
    }

    /** Divides each component of v2 to v1 and returns v1. */
    public static DivideInPlace(v1: Vector3, v2: Vector3): Vector3 {
        v1.x /= v2.x;
        v1.y /= v2.y;
        v1.z /= v2.z;
        return v1;
    }

    /** Returns the distance between the two vectors */
    public static Distance(v1: Vector3, v2: Vector3): number {
        return Vector3Api.Length(Vector3Api.Subtract(v2, v1));
    }

    /** Returns the squared distance between the two vectors */
    public static DistanceSquared(v1: Vector3, v2: Vector3): number {
        return Vector3Api.LengthSquared(Vector3Api.Subtract(v2, v1));
    }

    /** Returns the dot product of two vectors */
    public static Dot(v1: Vector3, v2: Vector3): number {
        return v1.x * v2.x + v1.y * v2.y + v1.z * v2.z;
    }

    /** Returns the cross product of two vectors */
    public static Cross(v1: Vector3, v2: Vector3): Vector3 {
        return { x: v1.y * v2.z - v1.z * v2.y,
                 y: v1.z * v2.x - v1.x * v2.z,
                 z: v1.x * v2.y - v1.y * v2.x };
    }
}