import { writable } from 'svelte/store';
import { v4 as uuidv4 } from 'uuid';
import { userStore } from './store';

class User {
    public projects = writable<any[]>();

    public signOut() {
        window.localStorage.clear();
        window.location.href = `${window.location.origin}/login`;
    }

    public get signedIn() {
        return !!(window.localStorage?.getItem('jwt') &&
            window.localStorage?.getItem('username'));
    }

    public get username() {
        return window.localStorage.getItem('username');
    }

    public get jwt() {
        return window.localStorage.getItem('jwt');
    }

    public saveCreds(jwt: string, username: string) {
        window.localStorage.setItem('jwt', jwt);
        window.localStorage.setItem('username', username);
    }

    public isAdmin() {
        return window.location.hostname === 'localhost' &&
            (user.username === 'afarchy' || user.username === 'demoUser');
    }

    public getLikes(projectId: string) {
        return !!window.localStorage.getItem('likes-' + projectId);
    }

    public setLikes(projectId: string, like: boolean) {
        if (like) {
            window.localStorage.setItem('likes-' + projectId, 'yes');
        } else {
            window.localStorage.removeItem('likes-' + projectId);
        }
    }

    public getTrialId() {
        return window.localStorage.getItem('trial-id');
    }

    public getOrCreateTrialId() {
        let trialId = window.localStorage.getItem('trial-id');
        if (!trialId) {
            trialId = uuidv4();
            window.localStorage.setItem('trial-id', trialId);
        }


        return trialId;
    }

    public get isTrial() {
        return !!this.getTrialId();
    }

    private async loadProject(file: string, array: any[], index: number) {
        const projectFile = await userStore.load(file);
        const json = JSON.parse(await projectFile.text());
        json.file = file;
        array[index] = json;
    }

    public async loadProjects() {
        const files = await userStore.list('project-');
        let projects: any[] = [].fill(null, 0, files.length);
        const promises: Promise<void>[] = [];
        for (let i = 0; i < files.length; i++) {
            promises.push(this.loadProject(files[i], projects, i));
        }

        await Promise.all(promises);
        projects.reverse();
        this.projects.set(projects);
    }
}

const user = new User();
export default user;