import * as BABYLON from '@babylonjs/core';
import { ColorProperty, RangeProperty } from '../models/property.js';
import type { Environment } from '../services/environment.js'
import { mediaStore } from '../services/store.js';

export class WinterLodgeEnvironmentProperties {
    public lightIntensity = new RangeProperty(0.1, 10, 1, false, '/docs?p=lights');
    public lightColor = new ColorProperty(BABYLON.Color3.White(), '/docs?p=lights');
    public highlightColor = new ColorProperty(BABYLON.Color3.White(), '/docs?p=lights');
}

export default class WinterLodgeEnvironment implements Environment {
    private root: BABYLON.TransformNode;;
    private shadows: BABYLON.ShadowGenerator;

    public async build(scene: BABYLON.Scene, properties: WinterLodgeEnvironmentProperties) {
        this.root = new BABYLON.TransformNode('winter-loft-root', scene);

        const light = new BABYLON.DirectionalLight("directional-light", new BABYLON.Vector3(1, -1, 1).normalize(), scene);
        properties.lightIntensity.value.subscribe(v => light.intensity = v);
        properties.lightColor.value.subscribe(() => light.diffuse = properties.lightColor.get());
        properties.highlightColor.value.subscribe(() => light.specular = properties.highlightColor.get());
        light.position = new BABYLON.Vector3(-20, 20, -20);
        light.parent = this.root;

        this.shadows = new BABYLON.ShadowGenerator(1024, light);
        this.shadows.useBlurExponentialShadowMap = true;

        const promise = new Promise<void>(resolve => {
            BABYLON.SceneLoader.ImportMesh('', mediaStore.getUrl('winter-loft.glb'), '', scene, (meshes) => {
                if (this.root.isDisposed()) {
                    meshes[0].dispose();
                } else {
                    meshes[0].parent = this.root;

                    meshes.forEach(m => {
                        if (m.material) {
                            if (m.material.name === 'Floor') {
                                m.receiveShadows = true;
                            } else {
                                this.shadows.addShadowCaster(m);
                            }
                        }

                        m.freezeWorldMatrix();
                    });
                }

                resolve();
            }, null, (_, message) => {
                console.error(message);
                resolve();
            });
        });

        const bounds = {
            min: { x: -3.5, y: 0, z: -3.5 },
            max: { x: 3.65, y: 6.3, z: 5.17 }
        };

        const center = {
            x: (bounds.max.x + bounds.min.x) * 0.5,
            y: (bounds.max.y + bounds.min.y) * 0.5,
            z: (bounds.max.z + bounds.min.z) * 0.5,
        };

        function makeBoundsBox(name: string, parent: BABYLON.TransformNode, width: number, height: number, depth: number, position: BABYLON.Vector3, quaternion: BABYLON.Quaternion = BABYLON.Quaternion.Identity()) {
            const box = BABYLON.CreateBox(name, { width, height, depth }, scene);
            box.parent = parent;
            box.position = position;
            box.rotationQuaternion = quaternion;
            box.freezeWorldMatrix();
            box.isVisible = false;

            if (scene.isPhysicsEnabled()) {
                box.physicsImpostor = new BABYLON.PhysicsImpostor(box, BABYLON.PhysicsImpostor.BoxImpostor, {
                    mass: 0, restitution: 0.9, ignoreParent: true, friction: 0.5
                }, scene);
            }

            box.isPickable = false;
            box.checkCollisions = true;
        }

        const extents = { x: bounds.max.x - bounds.min.x, y: bounds.max.y - bounds.min.y, z: bounds.max.z - bounds.min.x };

        makeBoundsBox('Left-Bounds', this.root, 1, extents.y, extents.z, new BABYLON.Vector3(bounds.min.x - 0.5, center.y, center.z));
        makeBoundsBox('Right-Bounds', this.root, 1, extents.y, extents.z, new BABYLON.Vector3(bounds.max.x + 0.5, center.y, center.z));
        makeBoundsBox('Bottom-Bounds', this.root, extents.x, 1, extents.z, new BABYLON.Vector3(center.x, bounds.min.y - 0.5, center.z));
        makeBoundsBox('Top-Bounds', this.root, extents.x, 1, extents.z, new BABYLON.Vector3(center.x, bounds.max.y + 0.5, center.z));
        makeBoundsBox('Front-Bounds', this.root, extents.x, extents.y, 1, new BABYLON.Vector3(center.x, center.y, bounds.max.z + 0.5));

        // Angled back wall
        makeBoundsBox('Back-Bounds', this.root, extents.x, extents.y + 1, 1, new BABYLON.Vector3(0.575, 3.45, -2.6), BABYLON.Quaternion.FromEulerAngles(27 * Math.PI / 180, 0, 0));

        // Stairs
        makeBoundsBox('Stairs-Bounds', this.root, 2, extents.y, extents.z, new BABYLON.Vector3(-2.8, center.y, 4.9));

        return promise.then(() => this.root);
    }

    public getSkyboxRotation(): BABYLON.Quaternion {
        return BABYLON.Quaternion.FromEulerAngles(0, Math.PI, 0);
    }

    public dispose() {
        this.root.dispose();
        this.shadows.dispose();
    }
}