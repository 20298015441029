export interface Serializable {
    serialize(): any;
    deserialize(data: any): any;
}

export function isSerializable(obj: any): obj is Serializable {
    return obj && typeof obj === 'object' && 'serialize' in obj && 'deserialize' in obj;
}

export function serializeProperties<T extends Serializable>(obj: T) {
    const data: any = {};
    for (const [key, value] of Object.entries(obj)) {
        if (isSerializable(value)) {
            data[key] = value.serialize();
        }
        else if (Array.isArray(value)) {
            data[key] = value.filter(x => isSerializable(x)).map(x => x.serialize());
        }
    }

    return data;
}

export function deserializeProperties<T extends Serializable>(obj: T, data: any) {
    for (const [key, value] of Object.entries(obj)) {
        if (key in data && isSerializable(value)) {
            value.deserialize(data[key]);
        }
    }
}

export function deserializeArray<T extends Serializable>(array: any, factory: (data: any) => T) {
    return array.map((data: any) => {
        const item = factory(data);
        item.deserialize(data);
        return item;
    });
}

export function duplicate<T extends Serializable>(obj: T, factory: (data: any) => T){
    const serialized = obj.serialize();
    const newObj = factory(serialized);
    newObj.deserialize(serialized);
    return newObj;
}