import * as BABYLON from '@babylonjs/core';
import RectNode from "../models/rectNode.js";
import ImageNode from "../models/imageNode.js";
import ModelNode from "../models/modelNode.js";
import Scene from "../models/scene.js";
import TextNode from "../models/textNode.js";
import BlockNode from "../models/blockNode.js";
import LightNode from "../models/lightNode.js";
import RectApi from "./rectApi.js";
import ImageApi from "./imageApi.js";
import MaterialApi from "./materialApi.js";
import ModelApi from "./modelApi.js";
import { SceneApi } from "./sceneApi.js";
import TextApi from "./textApi.js";
import BlockApi from "./blockApi.js";
import LightApi from "./lightApi.js";
import EmptyNode from '../models/emptyNode.js';
import WaypointNode from '../models/waypointNode.js';
import EmptyApi from './emptyApi.js';
import WaypointApi from './waypointApi.js';

export class Api {
    private apiMap = new Map<Object, Object>();

    private createApi(object: Object) {
        if (object instanceof ModelNode) {
            return new ModelApi(object);
        } else if (object instanceof TextNode) {
            return new TextApi(object);
        } else if (object instanceof ImageNode) {
            return new ImageApi(object);
        } else if (object instanceof RectNode) {
            return new RectApi(object);
        } else if (object instanceof BlockNode) {
            return new BlockApi(object);
        } else if (object instanceof LightNode) {
            return new LightApi(object);
        } else if (object instanceof EmptyNode) {
            return new EmptyApi(object);
        } else if (object instanceof WaypointNode) {
            return new WaypointApi(object);
        } else if (object instanceof Scene) {
            return new SceneApi(object);
        } else if (object instanceof BABYLON.PBRMaterial) {
            return new MaterialApi(object);
        }

        return null;
    }

    public get(object: Object) {
        if (!object) {
            return null;
        }

        let api = this.apiMap.get(object);
        if (!api) {
            api = this.createApi(object);
            this.apiMap.set(object, api);
        }

        return api;
    }
}


const api = new Api();
export default api;