import Node from './node.js'
import { AudioProperty, BoolProperty, AnimationProperty } from './property.js';

export default abstract class ObjectNode extends Node {
    public animation = new AnimationProperty();
    public sound = new AudioProperty();
    public adjustForUserHeight = new BoolProperty(false);

    public get inspectorProperties() {
        return [...super.inspectorProperties, 'animation', 'sound'];
    }
}