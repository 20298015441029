import { get } from "svelte/store";
import type Scene from "../models/scene.js";
import { project } from "../services/app.js";
import api from "./api.js";
import type ObjectApi from "./objectApi.js";

/** Represents a scene. Can be used to retrieve objects in the scene or transition to other scenes. */
export class SceneApi {
    constructor(private scene: Scene) {}

    /** Get all objects in the scene. */
    public getAllObjects(): ObjectApi[] {
        return this.scene.nodes.map(n => api.get(n) as ObjectApi).filter(obj => obj);
    }

    /** Returns the first object in the scene by name. */
    public getObjectByName(name: string): ObjectApi {
        const node = this.scene.nodes.find(n => n.name.get() === name);
        if (node) {
            return api.get(node) as ObjectApi;
        }

        return null;
    }

    /** Transitions to the next scene. */
    public goToNextScene() {
        const scenes = get(project).scenes;
        const idx = scenes.indexOf(this.scene);
        if (idx < scenes.length - 1) {
            get(project).transitionToScene(scenes.at(idx + 1).id.get());
        }
    }

    /** Transitions to the previous scene. */
    public goToPreviousScene() {
        const scenes = get(project).scenes;
        const idx = scenes.indexOf(this.scene);
        if (idx > 0) {
            get(project).transitionToScene(scenes.at(idx - 1).id.get());
        }
    }

    /** Transitions a scene by name. */
    public goToScene(name: string) {
        const scene = get(project).scenes.find(s => s.name.get() === name);
        if (!scene) {
            throw new Error("Cannot find scene: " + name);
        }

        get(project).transitionToScene(scene.id.get());
    }
}