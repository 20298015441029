import * as BABYLON from '@babylonjs/core';
import type { Environment } from '../services/environment.js'
import { app } from '../services/app.js';
import { mediaStore } from '../services/store.js';
import { ColorProperty, RangeProperty } from '../models/property.js';

export class EmptyEnvironmentProperties {
    public lightIntensity = new RangeProperty(0.1, 10, 1, false, '/docs?p=lights');
    public lightColor = new ColorProperty(BABYLON.Color3.White(), '/docs?p=lights');
    public highlightColor = new ColorProperty(BABYLON.Color3.White(), '/docs?p=lights');
    public groundLightColor = new ColorProperty(BABYLON.Color3.White(), '/docs?p=lights');
};

export default class EmptyEnvironment implements Environment {
    private root: BABYLON.TransformNode;;

    public async build(scene: BABYLON.Scene, properties: EmptyEnvironmentProperties) {
        this.root = new BABYLON.TransformNode('gallery-root', scene);

        const light = new BABYLON.HemisphericLight("hemispheric-light", new BABYLON.Vector3(1, 1, 0).normalize(), scene);
        light.parent = this.root;
        properties.lightIntensity.value.subscribe(v => light.intensity = v);
        properties.lightColor.value.subscribe(() => light.diffuse = properties.lightColor.get());
        properties.highlightColor.value.subscribe(() => light.specular = properties.highlightColor.get());
        properties.groundLightColor.value.subscribe(() => light.groundColor = properties.groundLightColor.get());

        if (app.editMode) {
            const groundMesh = BABYLON.CreateGround('ground', { width: 3, height: 3, subdivisions: 10 }, scene);
            groundMesh.receiveShadows = true;
            groundMesh.parent = this.root;

            const mat = new BABYLON.StandardMaterial("ground-material", scene);
            const url = mediaStore.getUrl('templategrid_albedo.png')
            const texture = new BABYLON.Texture(url, scene);
            texture.uScale = 1;
            texture.vScale = 1;
            mat.diffuseTexture = texture;
            mat.specularColor = BABYLON.Color3.Black();
            mat.emissiveTexture = texture;
            mat.alpha = 0.25;
            groundMesh.material = mat;
            groundMesh.freezeWorldMatrix();
        }

        return Promise.resolve(this.root);
    }

    public getSkyboxRotation(): BABYLON.Quaternion {
        return BABYLON.Quaternion.Identity();
    }

    public dispose() {
        this.root.dispose();
    }
}