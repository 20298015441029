import user from './user.js'

class Api {
    public async getContainer() {
        return await this.post('getContainer');
    }

    public async getTrialContainer(trialId: string) {
        return await this.post('getTrialContainer', `trialId=${trialId}`);
    }

    public async login(email: string, confirm: string, username: string, trialId: string, marketing: boolean = false) {
        return await this.post('login', `email=${email}&confirm=${confirm}&username=${username}&trialId=${trialId}&marketing=${marketing}`);
    }

    public async signup(email: string, redir: string) {
        let query = `email=${email}&origin=${window.location.origin}`;
        if (redir) {
            query += `&redir=${redir}`;
        }

        return await this.post('signup', query);
    }

    public async getSubscription() {
        return await this.post('getSubscription');
    }

    public async upgrade() {
        return await this.post('upgrade');
    }

    public async publish(projectFile: string) {
        return await this.post("publish", `project=${projectFile}`);
    }

    public async addLike(projectId: string) {
        await this.post("addLike", `projectId=${projectId}`);
    }

    public async removeLike(projectId: string) {
        await this.post("removeLike", `projectId=${projectId}`);
    }

    public async addView(projectId: string) {
        await this.post("addView", `projectId=${projectId}`);
    }

    public async createFromTemplate(template: string, trialId: string) {
        return await this.post("createFromTemplate", `template=${template}&trialId=${trialId}`);
    }

    public async getPurchased(productId: string) {
        return await this.post("getPurchased", `productId=${productId}`);
    }

    public async purchase(productId: string, projectId: string) {
        return await this.post('purchase', `productId=${productId}&projectId=${projectId}&origin=${window.location.origin}`);
    }

    public async getLinkCode(target: string) {
        return await this.post('getLinkCode', `target=${target}`);
    }

    public async getLinkFromCode(code: string) {
        return await this.post('getLinkFromCode', `linkCode=${code}`);
    }

    public async sketchfabLogin(authCode: string) {
        return await this.post('sketchfabLogin', `authCode=${authCode}`);
    }

    public async sketchfabMigrate(modelId: string, accessToken: string) {
        return await this.post('sketchfabMigrate', `modelId=${modelId}&accessToken=${accessToken}`);
    }

    public async getSpaceAvailable() {
        return await this.post('getSpaceAvailable');
    }

    private async post(name: string, body: string = ''): Promise<string> {
        let origin = window.location.origin;
        if (process.env.LOCAL_FUNC) {
            origin = 'http://localhost:7071';
        }

        const request = `${origin}/api/${name}`;

        if (process.env.LOCAL_FUNC) {
            console.log("Issuing POST request: " + request);
        }

        const response = await fetch(request, {
            method: 'post',
            headers: {
                'Custom-Authorization': `Bearer ${user.jwt}`,
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: body
        });

        return await this.handleResponse(response);
    }

    private async handleResponse(response: Response) {
        if (response.status === 200) {
            const message = await response.text();
            if (process.env.LOCAL_FUNC) {
                console.log("Got response: " + message);
            }
            return message;
        } else if (response.status === 400) {
            const message = await response.text();
            console.error("Got error: " + message);
            throw new Error(message);
        } else {
            console.error("Get unknown error: " + response.status + " - " + response.statusText);
            throw new Error(response.statusText);
        }
    }
}

const api = new Api();
export default api;