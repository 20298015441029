import * as BABYLON from '@babylonjs/core';
import { nodeFactories } from './node.js'
import ObjectNode from './objectNode.js';
import { ColorProperty, OptionProperty, PropertyGroup, RangeProperty } from "./property.js";

export enum LightType {
    Point = 'point-light',
    Spot = 'spot-light',
}

export default class LightNode extends ObjectNode {
    public lightType = new OptionProperty([...Object.values(LightType)], 0, true, '', '/docs?p=lights');
    public intensity = new RangeProperty(0.1, 10, 1, false, '/docs?p=lights');
    public range = new RangeProperty(0.1, 10, 1, false, '/docs?p=lights');
    public color = new ColorProperty(BABYLON.Color3.White(), '/docs?p=lights')
    public highlightColor = new ColorProperty(BABYLON.Color3.White(), '/docs?p=lights');
    public lightProperties = new PropertyGroup(this.lightType.value, [
        {}, // Point
        {   // Spotlight
            angle: new RangeProperty(0, 90, 45, false, '/docs?p=lights'),
            exponentialDecay: new RangeProperty(0.1, 100, 1, false, '/docs?p=lights')
        }
    ]);

    public scaleBy(scale: number) {}

    public get type() {
        return "light";
    }

    public get inspectorProperties() { return ['lightType', 'intensity', 'range', 'color', 'highlightColor', 'lightProperties', ...super.inspectorProperties]; }
}

nodeFactories.set('light', () => new LightNode());