import type * as BABYLON from '@babylonjs/core';
import EmptyEnvironment, { EmptyEnvironmentProperties } from '../environments/emptyEnvironment.js'
import GalleryEnvironment, { GalleryEnvironmentProperties } from '../environments/galleryEnvironment.js'
import WinterLoftEnvironment, { WinterLodgeEnvironmentProperties } from '../environments/winterLoftEnvironment.js'
import GroundEnvironment, { GroundEnvironmentProperties } from '../environments/groundEnvironment.js'
import MuseumEnvironment, { MuseumEnvironmentProperties } from '../environments/museumEnvironment.js'

export interface Environment {
    build(scene: BABYLON.Scene, properties: any): Promise<BABYLON.TransformNode>;
    getSkyboxRotation(): BABYLON.Quaternion;
    dispose(): void;
}

export const environmentFactories = new Map<string, () => Environment>();

environmentFactories.set('Empty', () => new EmptyEnvironment());
environmentFactories.set('Gallery', () => new GalleryEnvironment());
environmentFactories.set('Winter Loft', () => new WinterLoftEnvironment());
environmentFactories.set('Ground', () => new GroundEnvironment());
environmentFactories.set('Museum', () => new MuseumEnvironment());

export const environmentProperties = new Map<string, () => any>();
environmentProperties.set('Empty', () => new EmptyEnvironmentProperties());
environmentProperties.set('Gallery', () => new GalleryEnvironmentProperties());
environmentProperties.set('Winter Loft', () => new WinterLodgeEnvironmentProperties());
environmentProperties.set('Ground', () => new GroundEnvironmentProperties());
environmentProperties.set('Museum', () => new MuseumEnvironmentProperties());