import * as BABYLON from '@babylonjs/core';
import type { Environment } from '../services/environment.js'
import { mediaStore } from '../services/store.js';
import Subscriptions from '../util/subscriptions.js';
import { ColorProperty, RangeProperty } from '../models/property.js';

export class MuseumEnvironmentProperties {
    public lightIntensity = new RangeProperty(0.1, 10, 1, false, '/docs?p=lights');
    public lightColor = new ColorProperty(BABYLON.Color3.White(), '/docs?p=lights');
    public highlightColor = new ColorProperty(BABYLON.Color3.White(), '/docs?p=lights');
    public groundLightColor = new ColorProperty(BABYLON.Color3.White(), '/docs?p=lights');
};

export default class MuseumEnvironment implements Environment {
    private root: BABYLON.TransformNode;
    private subscriptions = new Subscriptions();
    private loadPromise: Promise<void>;

    public async build(scene: BABYLON.Scene, properties: MuseumEnvironmentProperties) {
        this.root = new BABYLON.TransformNode('museum-root', scene);

        const light = new BABYLON.HemisphericLight("environment-light", new BABYLON.Vector3(1, 1, 0).normalize(), scene);
        properties.lightIntensity.value.subscribe(v => light.intensity = v);
        properties.lightColor.value.subscribe(() => light.diffuse = properties.lightColor.get());
        properties.highlightColor.value.subscribe(() => light.specular = properties.highlightColor.get());
        properties.groundLightColor.value.subscribe(() => light.groundColor = properties.groundLightColor.get());
        light.parent = this.root;

        await this.loadPromise;

        const promise = new Promise<void>(resolve => {
            BABYLON.SceneLoader.ImportMesh('', mediaStore.getUrl('museum.glb'), '', scene, (meshes) => {
                if (this.root.isDisposed()) {
                    meshes[0].dispose();
                } else {
                    meshes[0].parent = this.root;
                    meshes[0].rotationQuaternion = BABYLON.Quaternion.FromEulerAngles(0, -Math.PI / 2, 0);

                    meshes.forEach(m => {
                        if (m.material) {
                            if (m.material.name === 'floor') {
                                m.receiveShadows = true;
                            }
                        }

                        m.freezeWorldMatrix();
                    });
                }

                resolve();
            }, null, (_, message) => {
                console.error(message);
                resolve();
            });
        });

        await promise;

        function makeBoundsBox(name: string, parent: BABYLON.TransformNode, width: number, height: number, depth: number, x: number, y: number, z: number, quaternion: BABYLON.Quaternion = BABYLON.Quaternion.Identity()) {
            const box = BABYLON.CreateBox(name, { width, height, depth }, scene);
            box.parent = parent;
            box.position = new BABYLON.Vector3(x, y, z);
            box.rotationQuaternion = quaternion;
            box.freezeWorldMatrix();
            box.isVisible = false;

            if (scene.isPhysicsEnabled()) {
                box.physicsImpostor = new BABYLON.PhysicsImpostor(box, BABYLON.PhysicsImpostor.BoxImpostor, {
                    mass: 0, restitution: 0.9, ignoreParent: true, friction: 0.5
                }, scene);
            }

            box.isPickable = false;
            box.checkCollisions = true;
        }

        makeBoundsBox('Ground-Bounds', this.root, 17, 1, 17, 0, -0.5, 4.6);

        makeBoundsBox('Right-Bounds', this.root, 1, 4, 17, 8.86, 2, 4.6);
        makeBoundsBox('Left-Bounds', this.root, 1, 4, 17, -8.46, 2, 4.6);
        makeBoundsBox('Back-Bounds', this.root, 17, 4, 1, 0, 2, -4.08);
        makeBoundsBox('Front-Bounds', this.root, 17, 4, 1, 0, 2, 13.24);

        makeBoundsBox('Front-Display', this.root, 8.24, 2.55, 0.25, 0.2, 1.24, 10.58);
        makeBoundsBox('Left-Front-Display', this.root, 0.25, 2.55, 5.11, -5.8, 1.22, 8.15);
        makeBoundsBox('Left-Back-Display', this.root, 0.25, 2.55, 5.11, -5.8, 1.22, 1.01);
        makeBoundsBox('Right-Front-Display', this.root, 0.25, 2.55, 5.11, 6.20, 1.22, 8.15);
        makeBoundsBox('Right-Back-Display', this.root, 0.25, 2.55, 5.11, 6.20, 1.22, 1.01);

        return this.root;
    }

    public getSkyboxRotation(): BABYLON.Quaternion {
        return BABYLON.Quaternion.Identity();
    }

    public dispose() {
        this.root.dispose();
        this.subscriptions.dispose();
    }
}