import type ImageNode from '../models/imageNode.js';
import ObjectApi from './objectApi.js';

/** Represents an image object in the scene. */
export default class ImageApi extends ObjectApi {
    constructor(private imageNode: ImageNode) {
        super(imageNode);
    }

    /** Returns the width of this image in meters. */
    public getWidth(): number {
        return this.imageNode.width.get();
    }

    /** Sets the width of this image in meters. */
    public setWidth(width: number) {
        this.imageNode.width.set(width);
    }
}
