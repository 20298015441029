import { Subscriber, Updater, writable } from 'svelte/store';

export class ArrayStore<T> {
    private store = writable<T[]>();
    private value: T[];

    constructor(v: T[] = []) {
        this.set(v);
    }

    public set(v: T[]) {
        this.value = v;
        this.store.set(v);
    }

    public get() {
        return this.value;
    }

    public subscribe(run: Subscriber<T[]>, invalidate?: (value?: T[]) => void) {
        return this.store.subscribe(run, invalidate);
    }

    public update(updater: Updater<T[]>) {
        this.store.update(updater);
    }

    public push(...items: T[]) {
        this.value.push(...items);
        this.store.update(s => s);
    }

    public pop() {
        const r = this.value.pop();
        this.store.update(s => s);
        return r;
    }

    public unshift(...items: T[]) {
        this.value.unshift(...items);
        this.store.update(s => s);
    }

    public shift() {
        const r = this.value.shift();
        this.store.update(s => s);
        return r;
    }

    public reverse() {
        const r = this.value.reverse();
        this.store.update(s => s);
        return r;
    }

    public slice(start: number, end: number) {
        return this.value.slice(start, end);
    }

    public splice(start: number, deleteCount?: number): T[];

    public splice(start: number, deleteCount: number, ...items: T[]): T[];

    public splice(start: number, deleteCount: number, ...items: T[]) {
        const r = this.value.splice(start, deleteCount, ...items);
        this.store.update(s => s);
        return r;
    }

    public find(predicate: (t: T) => boolean) {
        return this.value.find(predicate);
    }

    public get length() {
        return this.value.length;
    }

    public clear() {
        this.splice(0, this.length);
    }

    public map<V>(f: (t: T) => V) {
        return this.value.map(f);
    }

    public indexOf(t: T) {
        return this.value.indexOf(t);
    }

    public at(index: number) {
        return this.value[index];
    }

    public includes(t: T) {
        return this.value.includes(t);
    }
}

export function array<T>(value: T[] = []) {
    return new ArrayStore(value);
}