import ObjectApi from './objectApi.js';
import * as BABYLON from '@babylonjs/core';
import LightNode, { LightType } from '../models/lightNode.js';

/** Represents a light object in the scene. */
export default class LightApi extends ObjectApi {
    constructor(private lightNode: LightNode) {
        super(lightNode);
    }

    /** Gets the type of this light */
    public getLightType(): string {
        return this.lightNode.lightType.getSelected();
    }

    /** Sets the type of this light. Options are point-light and spot-light. */
    public setLightType(lightType: string) {
        this.lightNode.lightType.setSelected(lightType);
    }

    /** Returns the intensity of this light. */
    public getIntensity(): number {
        return this.lightNode.intensity.get();
    }

    /** Sets the intensity of this light. */
    public setIntensity(intensity: number) {
        this.lightNode.intensity.set(intensity);
    }

    /** Returns the range of this light. */
    public getRange(): number {
        return this.lightNode.range.get();
    }

    /** Sets the range of this light. */
    public setRange(range: number) {
        this.lightNode.range.set(range);
    }

    /** Returns the color of this light in hex format. */
    public getColor(): string {
        return this.lightNode.color.get().toHexString();
    }

    /** Sets the color of this light in hex format. */
    public setColor(color: string) {
        this.lightNode.color.set(BABYLON.Color3.FromHexString(color));
    }

    /** Returns the highlightColor of this light in hex format. */
    public getHighlightColor(): string {
        return this.lightNode.highlightColor.get().toHexString();
    }

    /** Sets the highlightColor of this light in hex format. */
    public setHighlightColor(highlightColor: string) {
        this.lightNode.highlightColor.set(BABYLON.Color3.FromHexString(highlightColor));
    }

    /** Returns the angle of this light. Only applies to spotlights. */
    public getAngle(): number {
        return this.lightNode.lightProperties.options[this.lightNode.lightType.options.indexOf(LightType.Spot)].angle.get();
    }

    /** Sets the angle of this light. Only applies to spotlights. */
    public setAngle(angle: number) {
        this.lightNode.lightProperties.options[this.lightNode.lightType.options.indexOf(LightType.Spot)].angle.set(angle);
    }

    /** Returns the exponentialDecay of this light. Only applies to spotlights. */
    public getExponentialDecay(): number {
        return this.lightNode.lightProperties.options[this.lightNode.lightType.options.indexOf(LightType.Spot)].exponentialDecay.get();
    }

    /** Sets the exponentialDecay of this light. Only applies to spotlights. */
    public setExponentialDecay(exponentialDecay: number) {
        this.lightNode.lightProperties.options[this.lightNode.lightType.options.indexOf(LightType.Spot)].exponentialDecay.set(exponentialDecay);
    }
}