import api from './api.js'
import modalManager, { ModalType } from './modalManager.js'
import user from './user.js';

export default async function paywall(prompt: boolean) {
    try {
        // if (user.signedIn) {
        //     const subscription = await api.getSubscription();
        //     if (subscription === 'Pro') {
        //         return true;
        //     }
        // }

        // if (prompt) {
        //     modalManager.push(ModalType.Upgrade);
        // }
    } catch (e) {}

    return true;
}