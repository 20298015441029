import { Writable, get } from 'svelte/store';
import type { ArrayStore } from '../util/array.js';
import * as Util from '../util/util.js'

export interface Action {
    action: () => void;
    undoAction: () => void;
}

export class Edit {
    public onIdle = async () => {};

    private index: number = -1;
    private actions: Action[] = [];
    private batch: Action[] = null;
    private idleTimeout: NodeJS.Timeout;
    private batchDepth = 0;
    public idle = true;

    public do(action: Action) {
        this.restartIdleTimer();

        if (this.batch) {
            this.batch.push(action);
            action.action();
        } else {
            this.trim();
            this.actions.push(action);
            this.redo();
        }
    }

    public undo() {
        this.restartIdleTimer();

        if (this.index >= 0) {
            this.actions[this.index].undoAction();
            this.index--;
        }
    }

    public redo() {
        this.restartIdleTimer();

        if (this.index < this.actions.length - 1) {
            this.index++;
            this.actions[this.index].action();
        }
    }

    public set<T>(writable: Writable<T>, value: T) {
        const current = get(writable);
        this.do({
            action: () => writable.set(value),
            undoAction: () => writable.set(current)
        });
    }

    public setProperty<T, V>(writable: Writable<T>, property: string, value: V) {
        const current = (get(writable) as any)[property];
        this.do({
            action: () => {
                (get(writable) as any)[property] = value;
                writable.set(get(writable));
            },
            undoAction: () => {
                (get(writable) as any)[property] = current;
                writable.set(get(writable));
            }
        });
    }

    public pushAndUpdate<T, S>(writable: Writable<T>, array: S[], value: S) {
        this.do({
            action: () =>
                writable.update(v => {
                    array.push(value);
                    return v;
                }),
            undoAction: () =>
                writable.update(v => {
                    array.pop();
                    return v;
                })
        });
    }

    public push<T>(array: Util.ArrayLike<T>, value: T) {
        this.do({
            action: () => array.push(value),
            undoAction: () => Util.remove(array, value)
        });
    }

    public setArray<T>(array: ArrayStore<T>, value: T[]) {
        const oldValue = array.get();
        this.do({
            action: () => array.set(value),
            undoAction: () => array.set(oldValue)
        });
    }

    public removeAndUpdate<T, S>(writable: Writable<T>, array: S[], value: S) {
        const idx = array.indexOf(value);
        if (idx >= 0) {
            this.do({
                action: () =>
                    writable.update(v => {
                        Util.remove(array, value);
                        return v;
                    }),
                undoAction: () =>
                    writable.update(v => {
                        array.splice(idx, 0, value);
                        return v;
                    })
            });
        }
    }

    public insertAndUpdate<T, S>(writable: Writable<T>, array: S[], value: S, index: number) {
        this.do({
            action: () =>
                writable.update(v => {
                    array.splice(index, 0, value);
                    return v;
                }),
            undoAction: () =>
                writable.update(v => {
                    array.splice(index, 1);
                    return v;
                })
        });
    }

    public insert<T>(array: Util.ArrayLike<T>, value: T, index: number) {
        this.do({
            action: () => array.splice(index, 0, value),
            undoAction: () => array.splice(index, 1)
        });
    }

    public remove<T>(array: Util.ArrayLike<T>, value: T) {
        const idx = array.indexOf(value);
        if (idx >= 0) {
            this.do({
                action: () => Util.remove(array, value),
                undoAction: () => array.splice(idx, 0, value)
            });
        }
    }

    public startBatch() {
        if (this.batchDepth === 0) {
            this.batch = [];
        }

        this.batchDepth++;
    }

    public endBatch() {
        this.batchDepth--;
        if (this.batchDepth === 0) {
            this.trim();
            const batch = this.batch;
            this.actions.push({
                action: () => batch.forEach(a => a.action()),
                undoAction: () => batch.slice().reverse().forEach(a => a.undoAction()),
            });

            this.index++;
            this.batch = null;
        }

        if (this.batchDepth < 0) {
            this.batchDepth = 0;
            console.error("Batch end called with no batch start.")
        }
    }

    private trim() {
        this.actions.splice(this.index + 1, this.actions.length - this.index - 1);
    }

    public async setIdle() {
        if (!this.idle) {
            clearTimeout(this.idleTimeout);
            this.idle = true;
            await this.onIdle();
        }
    }

    public restartIdleTimer() {
        this.idle = false;
        clearTimeout(this.idleTimeout);
        this.idleTimeout = setTimeout(() => {
            this.setIdle();
        }, 5000);
    }
}

const edit = new Edit();
export default edit;