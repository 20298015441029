import * as BABYLON from '@babylonjs/core';
import * as Util from '../util/util.js';
import type { Quaternion, Vector3 } from "../models/property";
import Vector3Api from "./vector3Api";

/** Used to represent a rotation. */
export default class QuaternionApi {

    /** Creates a new Quaternion. */
    public static Create(x = 0, y = 0, z = 0, w = 1): Quaternion {
        return { x, y, z, w };
    }

    /** Creates a new Quaternion as a rotation around an axis by some angle in radians. */
    public static CreateAxisAngle(axis: Vector3, angle: number): Quaternion {
        const q = QuaternionApi.Create();
        return QuaternionApi.SetAxisAngle(q, axis, angle);
    }

    /** Creates a new Quaternion from euler angles in radians. */
    public static CreateEuler(x: number, y: number, z: number): Quaternion {
        const q = QuaternionApi.Create();
        return QuaternionApi.SetEuler(q, x, y, z);
    }

    /** Creates a new copy of a Quaternion. */
    public static Copy(q: Quaternion): Quaternion {
        return { x: q.x, y: q.y, z: q.z, w: q.w };
    }

    /** Copies the values from q2 to q1 and returns q1. */
    public static CopyInPlace(q1: Quaternion, q2: Quaternion): Quaternion {
        q1.x = q2.x;
        q1.y = q2.y;
        q1.z = q2.z;
        q1.w = q2.w;
        return q1;
    }

    /** Creates a new Quaternion as a rotation around an axis by some angle in radians. */
    public static SetAxisAngle(q: Quaternion, axis: Vector3, angle: number): Quaternion {
        const v = Util.VecTmp[0].copyFromFloats(axis.x, axis.y, axis.z);
        BABYLON.Quaternion.RotationAxisToRef(v, angle, Util.QuatTmp[0]);
        return QuaternionApi.CopyInPlace(q, Util.QuatTmp[0]);
    }

    /** Creates a new Quaternion from euler angles in radians. */
    public static SetEuler(q: Quaternion, x: number, y: number, z: number): Quaternion {
        BABYLON.Quaternion.FromEulerAnglesToRef(x, y, z, Util.QuatTmp[0]);
        return QuaternionApi.CopyInPlace(q, Util.QuatTmp[0]);
    }

    /** Multiplies two quaternions, which combines the two rotations. */
    public static Multiply(q1: Quaternion, q2: Quaternion): Quaternion {
        const result = QuaternionApi.Copy(q1);
        return this.MultiplyInPlace(result, q2);
    }

    /** Multiplies two quaternions, which combines the two rotations into q1, and returns q1. */
    public static MultiplyInPlace(q1: Quaternion, q2: Quaternion): Quaternion {
        const x = q1.x * q2.w + q1.y * q2.z - q1.z * q2.y + q1.w * q2.x;
        const y = -q1.x * q2.z + q1.y * q2.w + q1.z * q2.x + q1.w * q2.y;
        const z = q1.x * q2.y - q1.y * q2.x + q1.z * q2.w + q1.w * q2.z;
        const w = -q1.x * q2.x - q1.y * q2.y - q1.z * q2.z + q1.w * q2.w;
        q1.x = x;
        q1.y = y;
        q1.z = z;
        q1.w = w;
        return q1;
    }

    /** Rotates a vector by the quaternion. */
    public static RotateVector(q: Quaternion, v: Vector3): Vector3 {
        const result = Vector3Api.Copy(v);
        return QuaternionApi.RotateVectorInPlace(q, result);
    }

    /** Rotates a vector by the quaternion in place, and returns the vector. */
    public static RotateVectorInPlace(q: Quaternion, v: Vector3): Vector3 {
        Util.VecTmp[0].copyFromFloats(v.x, v.y, v.z);
        Util.VecTmp[0].rotateByQuaternionToRef(Util.QuatTmp[0].copyFromFloats(q.x, q.y, q.z, q.w), Util.VecTmp[1]);
        v.x = Util.VecTmp[1].x;
        v.y = Util.VecTmp[1].y;
        v.z = Util.VecTmp[1].z;
        return v;
    }
}