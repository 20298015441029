import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import user from './user.js'

interface EventProperties {
    [key: string]: any;
}

class Insights {
    private appInsights: ApplicationInsights;

    constructor() {
        this.appInsights = new ApplicationInsights({
            config: {
                connectionString: 'InstrumentationKey=e8ed6adc-33c7-4097-b3d7-3ab2d13d8b54;IngestionEndpoint=https://centralus-2.in.applicationinsights.azure.com/'
            }
        });

        this.appInsights.loadAppInsights();
    }

    // A thing happened. Should be for business-related events.
    public trackEvent(name: string, props: EventProperties = null) {
        this.appInsights.trackEvent({name}, props);
    }

    // Something was displayed to the user
    public trackPageView(name: string, props: EventProperties = {}) {
        props.XRAvailable = !!(navigator as any).xr;
        this.appInsights.trackPageView({name, isLoggedIn: user.signedIn, properties: props});
    }

    public trackPageViewPerformance(name: string, uri: string) {
        this.appInsights.trackPageViewPerformance({name, uri});
    }

    // Log a caught exception (uncaught exceptions are logged automatically)
    public trackException(exception: Error) {
        this.appInsights.trackException({exception});
    }

    // A thing happened. Should be used for "what happened?" debugging purposes.
    public trackTrace(message: string) {
        this.appInsights.trackTrace({message});
    }

    // Send regular reports of some state like FPS
    public trackMetric(name: string, average: number, props: EventProperties = null) {
        this.appInsights.trackMetric({name, average}, props);
    }

    // Track dependency responses. Some of these are already automatically logged.
    public trackDependencyData(id: string, name: string, responseCode: number) {
        this.appInsights.trackDependencyData({id, name, responseCode});
    }

    // Same as trackPageView but lets you control start & stop manually
    public startTrackPage(name: string) {
        this.appInsights.startTrackPage(name);
    }

    // Same as trackPageView but lets you control start & stop manually
    public stopTrackPage(name: string) {
        this.appInsights.stopTrackPage(name, null);
    }

    // Track some other timed event
    public startTrackEvent(name: string) {
        this.appInsights.startTrackEvent(name);
    }

    public stopTrackEvent(name: string, props: EventProperties = null) {
        this.appInsights.stopTrackEvent(name, props);
    }

    // Only use this if we encounter a critical error
    public flush() {
        this.appInsights.flush();
    }
}

const insights = new Insights;
export default insights;