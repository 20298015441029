import * as BABYLON from '@babylonjs/core';
import { get } from 'svelte/store';
import InteractableNode from '../models/interactableNode.js';
import { NodeFunction } from '../models/node.js';
import type Node from '../models/node.js';
import type { Quaternion, Vector3 } from '../models/property.js';
import { project } from '../services/app.js';
import { EaseCurves, Keyframe } from './animationApi.js';
import api from './api.js';
import * as NodeUtil from '../util/nodeUtil.js';
import * as Util from '../util/util.js';
import Event from '../util/event.js';
import Subscriptions from '../util/subscriptions.js';
import ObjectNode from '../models/objectNode.js';

/** Base class for all types of objects in the scene. */
export default class ObjectApi {
    private _update = new Event<number>();
    private _updateSubscriptions = new Subscriptions();

    constructor(public node: Node) {}

    /** Returns the name of this object. */
    public getName(): string {
        return this.node.name.get();
    }

    /** Sets the name of this object. */
    public setName(value: string) {
        this.node.name.set(value);
    }

    /**
     * Returns an array of child objects.
     * If 'recurse' is true, returns all descendent objects in depth first order.
     */
    public getChildren(recurse = false): ObjectApi[] {
        return this.node.getChildren(recurse).map(n => api.get(n) as ObjectApi);
    }

    /** Returns the child object a the specified index. */
    public getChild(index: number): ObjectApi {
        return api.get(this.node.getChild(index)) as ObjectApi;
    }

    /** Sets the parent of object, optionally keeping the current position */
    public setParent(parent: ObjectApi, keepCurrentPosition: boolean): void {
        NodeUtil.setParent(this.node, parent.node, keepCurrentPosition, this.node.childIds.length);
    }

    /** Returns the parent object of this object.  */
    public getParent(): ObjectApi {
        return api.get(this.node.getParent()) as ObjectApi;
    }

    private get transformNode() {
        return get(this.node.transformNode);
    }

    /** Returns the X (horizontal) position of this object in local space. */
    public getX(): number {
        return this.transformNode.position.x;
    }

    /** Sets the X (horizontal) position of this object in local space. */
    public setX(value: number) {
        this.node.position.setAxis('x', value);
    }

    /** Returns the Y (vertical) position of this object in local space. */
    public getY(): number {
        return this.transformNode.position.y;
    }

    /** Sets the Y (vertical) position of this object in local space. */
    public setY(value: number) {
        this.node.position.setAxis('y', value);
    }

    /** Sets the Z (depth) position of this object in local space. */
    public getZ(): number {
        return this.transformNode.position.z;
    }

    /** Gets the Z (depth) position of this object in local space. */
    public setZ(value: number) {
        this.node.position.setAxis('z', value);
    }

    /** Gets the position of this object in local space. */
    public getPosition(): Vector3 {
        return { x: this.getX(), y: this.getY(), z: this.getZ() };
    }

    /** Sets the position of this object in local space. */
    public setPosition(position: Vector3) {
        this.node.position.value.set(position);
    }

    /** Gets the rotation around the X (horizontal) axis of this object in local space in radians. */
    public getRotationX(): number {
        return this.transformNode.rotationQuaternion.toEulerAngles().x;
    }

    /** Sets the rotation around the X (horizontal) axis of this object in local space in radians. */
    public setRotationX(value: number) {
        this.node.rotation.set(BABYLON.Quaternion.FromEulerAngles(value, this.getRotationY(), this.getRotationZ()));
    }

    /** Gets the rotation around the Y (vertical) axis of this object in local space in radians. */
    public getRotationY(): number {
        return this.transformNode.rotationQuaternion.toEulerAngles().y;
    }

    /** Sets the rotation around the Y (vertical) axis of this object in local space in radians. */
    public setRotationY(value: number) {
        this.node.rotation.set(BABYLON.Quaternion.FromEulerAngles(this.getRotationX(), value, this.getRotationZ()));
    }

    /** Gets the rotation around the Z (depth) axis of this object in local space in radians. */
    public getRotationZ(): number {
        return this.transformNode.rotationQuaternion.toEulerAngles().z;
    }

    /** Sets the rotation around the Z (depth) axis of this object in local space in radians. */
    public setRotationZ(value: number) {
        this.node.rotation.set(BABYLON.Quaternion.FromEulerAngles(this.getRotationX(), this.getRotationY(), value));
    }

    /** Gets the rotation of this object in local space as a quaternion with values { x, y, z, w } */
    public getQuaternion(): Quaternion {
        const q = this.transformNode.rotationQuaternion;
        return { x: q.x, y: q.y, z: q.z, w: q. w };
    }

    /** Sets the rotation of this object in local space as a quaternion with values { x, y, z, w } */
    public setQuaternion(value: Quaternion) {
        this.node.rotation.value.set(value);
    }

    /** Rotates the object to face the target. Optionally, aligns the y-axis to gravity. */
    public rotateTowards(target: Vector3, alignWithGravity = false) {
        this.setQuaternion(Util.lookAt(this.node.position.toVector3(), new BABYLON.Vector3(target.x, target.y, target.z), alignWithGravity));
    }

    /** Gets the uniform local scale of this object. */
    public getScale(): number {
        return this.transformNode.scaling.x;
    }

    /** Sets the uniform local scale of this object. */
    public setScale(value: number) {
        this.node.scale.set(BABYLON.Vector3.One().scale(value));
    }

    /** Returns if this object will receive click events. */
    public getEnabled(): boolean {
        return this.node.enabled.get();
    }

    /** Sets if this object will receive click events. */
    public setEnabled(enabled: boolean) {
        this.node.enabled.set(enabled);
    }

    /** Returns if this object will receive click events. */
    public getClickEnabled(): boolean {
        if (this.node instanceof InteractableNode) {
            return this.node.clickEnabled.get();
        }

        return false;
    }

    /** Sets if this object will receive click events. */
    public setClickEnabled(enabled: boolean) {
        if (this.node instanceof InteractableNode) {
            this.node.clickEnabled.set(enabled);
        }
    }

    /** Returns whether this object can be grabbed by the user. */
    public getGrabEnabled(): boolean {
        if (this.node instanceof InteractableNode) {
            return this.node.grabEnabled.get();
        }

        return false;
    }

    /** Sets whether this object can be grabbed by the user. */
    public setGrabEnabled(enabled: boolean) {
        if (this.node instanceof InteractableNode) {
            this.node.grabEnabled.set(enabled);
        }
    }

    private _animateTo(kf: Keyframe) {
        let grp = new BABYLON.AnimationGroup('script-animation');
        kf.easing = kf.easing || EaseCurves.Linear;
        const easingFunc = new BABYLON.BezierCurveEase(kf.easing[0], kf.easing[1], kf.easing[2], kf.easing[3]);
        const framesPerSecond = 30;
        const endTime = framesPerSecond * kf.time;

        const addAnimation = (prop: string, startValue: any, endValue: any, type: number) => {
            const anim = new BABYLON.Animation('script-animation-' + prop, prop, framesPerSecond, type, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
            anim.setKeys([
                { frame: 0, value: startValue },
                { frame: endTime, value: endValue }
            ]);
            anim.setEasingFunction(easingFunc);
            grp.addTargetedAnimation(anim, this.transformNode);
        };

        if (kf.position) {
            kf.x = kf.position.x;
            kf.y = kf.position.y;
            kf.z = kf.position.z;
        }

        if (kf.x !== undefined) {
            addAnimation('position.x', this.getX(), kf.x, BABYLON.Animation.ANIMATIONTYPE_FLOAT);
        }

        if (kf.y !== undefined) {
            addAnimation('position.y', this.getY(), kf.y, BABYLON.Animation.ANIMATIONTYPE_FLOAT);
        }

        if (kf.z !== undefined) {
            addAnimation('position.z', this.getZ(), kf.z, BABYLON.Animation.ANIMATIONTYPE_FLOAT);
        }

        if (kf.quaternion) {
            const endValue = new BABYLON.Quaternion(kf.quaternion.x, kf.quaternion.y, kf.quaternion.z, kf.quaternion.w);
            addAnimation('rotationQuaternion', this.transformNode.rotationQuaternion, endValue, BABYLON.Animation.ANIMATIONTYPE_QUATERNION);
        } else if (kf.rotationX !== undefined || kf.rotationY !== undefined || kf.rotationZ !== undefined) {
            const endX = kf.rotationX !== undefined ? kf.rotationX : this.getRotationX();
            const endY = kf.rotationY !== undefined ? kf.rotationY : this.getRotationY();
            const endZ = kf.rotationZ !== undefined ? kf.rotationZ : this.getRotationZ();
            const endValue = BABYLON.Quaternion.FromEulerAngles(endX, endY, endZ);
            addAnimation('rotationQuaternion', this.transformNode.rotationQuaternion, endValue, BABYLON.Animation.ANIMATIONTYPE_QUATERNION);
        }

        if (kf.scale !== undefined) {
            addAnimation('scaling', this.transformNode.scaling, BABYLON.Vector3.One().scale(kf.scale), BABYLON.Animation.ANIMATIONTYPE_VECTOR3);
        }

        const promise = new Promise<void>(resolve => {
            this.syncNodeTransform();
            grp.onAnimationEndObservable.addOnce(() => resolve());
        });

        grp.start();
        return promise;
    }

    /**
     * Animates this object from its current position to each passed in Keyframe in sequence.
     */
    public async animateTo(...keyframes: Keyframe[]) {
        for (const kf of keyframes) {
            await this._animateTo(kf)
        }
    }

    /** Stops all animations running on this object */
    public async stopAnimations() {
        const tn = get(this.node.transformNode);
        tn.getScene().stopAnimation(tn);
        if (this.node instanceof ObjectNode) {
            this.node.animation.get()?.stop(this.node);
        }

        this.syncNodeTransform();
    }

    /** Creates a complete copy of this object at its current state. */
    public async duplicate() {
        this.syncNodeTransform();
        const duplicate = get(project).duplicateNode(this.node);
        await duplicate.loadedPromise;
        return api.get(duplicate);
    }

    /** Removes this object all of its children from the scene permanently. */
    public delete() {
        get(project).deleteNode(this.node);
    }

    private syncNodeTransform() {
        this.node.position.set(this.transformNode.position);
        this.node.rotation.set(this.transformNode.rotationQuaternion);
        this.node.scale.set(this.transformNode.scaling);
    }

    /** Plays or resumes the sound attached to this object */
    public playSound(playFromBeginning = false) {
        if (playFromBeginning) {
            this.node.callFunction(NodeFunction.PlaySoundFromBeginning)
        } else {
            this.node.callFunction(NodeFunction.ResumeSound);
        }
    }

    /** Stops the sound attached to this object. */
    public stopSound() {
        this.node.callFunction(NodeFunction.StopSound);
    }

    /** Registers a callback to invoked before every render frame, with the amount of time that has passed since the last frame. Invoke the returned callback to unregister. */
    public onUpdate(callback: (deltaTime: number) => void): () => void {
        this._update.subscribe(callback);
        if (this._update.count() === 1) {
            const tn = get(this.node.transformNode);
            const scene = tn.getScene();
            this._updateSubscriptions.on(scene.onBeforeRenderObservable, () => {
                this._update.invoke(scene.deltaTime);
            });

            tn.onDisposeObservable.add(() => this._updateSubscriptions.dispose());
        }

        return () => {
            this._update.unsubscribe(callback);
            if (this._update.count() === 0) {
                this._updateSubscriptions.dispose();
            }
        };
    }

    /** Registers a callback to invoked when the object is clicked. Invoke the returned callback to unregister. */
    public onClick(callback: () => void): () => void {
        return get(this.node.behavior).click.subscribe(callback);
    }
}