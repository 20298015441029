import { writable } from 'svelte/store';
import * as Util from '../util/util.js';

export default class Event<T> {
    public hasListener = writable(false);
    private _callbacks: ((t: T) => void)[] = [];

    public subscribe(cb: (t: T) => void): () => void {
        this._callbacks.push(cb);
        this.hasListener.set(true);
        return () => this.unsubscribe(cb);
    }

    public unsubscribe(cb: (t: T) => void) {
        Util.remove(this._callbacks, cb);
        this.hasListener.set(this._callbacks.length === 0);
    }

    public invoke(t: T) {
        for (const cb of this._callbacks) {
            cb(t);
        }
    }

    public count() {
        return this._callbacks.length;
    }

     public clear() {
        this._callbacks = [];
     }
}