import * as BABYLON from '@babylonjs/core';
import { ColorProperty, OptionProperty, RangeProperty } from '../models/property.js';
import type { Environment } from '../services/environment.js'
import { mediaStore } from '../services/store.js';
import Subscriptions from '../util/subscriptions.js';
import * as Util from '../util/util.js';

export class GroundEnvironmentProperties {
    public ground = new OptionProperty([
        'brick',
        'clovers',
        'concrete',
        'cracks',
        'desert-cracks',
        'desert-mountain',
        'desert-sand',
        'desert-sand-2',
        'desert-sand-3',
        'desert-sand-4',
        'desert-stone',
        'dry-ground',
        'ferns',
        'grass',
        'grass-autumn-orange',
        'grass-autumn-red',
        'lava',
        'lava-2',
        'plastic',
        'savanna',
        'snow',
        'snow-grass',
        'wood',
    ], 1, true, '', '/docs?p=environments');

    public lightIntensity = new RangeProperty(0.1, 10, 1, false, '/docs?p=lights');
    public lightColor = new ColorProperty(BABYLON.Color3.White(), '/docs?p=lights');
    public highlightColor = new ColorProperty(BABYLON.Color3.White(), '/docs?p=lights');
    public groundLightColor = new ColorProperty(BABYLON.Color3.White(), '/docs?p=lights');
};

export default class GroundEnvironment implements Environment {
    private root: BABYLON.TransformNode;
    private groundMesh: BABYLON.AbstractMesh;
    private subscriptions = new Subscriptions();
    private loadPromise: Promise<void>;
    private properties: GroundEnvironmentProperties;

    public async build(scene: BABYLON.Scene, properties: GroundEnvironmentProperties) {
        this.properties = properties;
        this.root = new BABYLON.TransformNode('ground-root', scene);

        const light = new BABYLON.HemisphericLight("hemispheric-light", new BABYLON.Vector3(1, 1, 0).normalize(), scene);
        properties.lightIntensity.value.subscribe(v => light.intensity = v);
        light.parent = this.root;
        properties.lightColor.value.subscribe(() => light.diffuse = properties.lightColor.get());
        properties.highlightColor.value.subscribe(() => light.specular = properties.highlightColor.get());
        properties.groundLightColor.value.subscribe(() => light.groundColor = properties.groundLightColor.get());

        const ground = BABYLON.CreateGround('ground', { width: 60, height: 60, subdivisions: 2 }, scene);
        ground.parent = this.root;
        const groundMat = new BABYLON.StandardMaterial('ground-mat', scene);
        ground.material = groundMat;
        this.groundMesh = ground;
        ground.receiveShadows = true;
        ground.freezeWorldMatrix();
        ground.checkCollisions = true;

        this.subscriptions.add(this.properties.ground.value.subscribe(_ => {
            this.loadPromise = this.updateGround();
        }));

        await this.loadPromise;

        if (scene.isPhysicsEnabled()) {
            const box = BABYLON.CreateBox('ground-box', { width: 60, height: 1, depth: 60 }, scene);
            box.parent = this.root;
            box.position.y = -0.5;
            box.freezeWorldMatrix();
            box.isVisible = false;

            box.physicsImpostor = new BABYLON.PhysicsImpostor(box, BABYLON.PhysicsImpostor.BoxImpostor, {
                mass: 0, restitution: 0.9, ignoreParent: true, friction: 0.5
            }, scene);

            box.isPickable = false;
        };

        return Promise.resolve(this.root);
    }

    private async updateGround() {
        const scene = this.root.getScene();
        const files = await mediaStore.list('ground-' + this.properties.ground.getSelected() + '_');

        const mat = this.groundMesh.material as BABYLON.StandardMaterial;
        mat.diffuseTexture?.dispose();
        mat.diffuseTexture = null;
        mat.specularTexture?.dispose();
        mat.specularTexture = null;
        mat.bumpTexture?.dispose();
        mat.bumpTexture = null;

        for (const file of files) {
            if (Util.basename(file, false).endsWith('_d')) {
                const diffuseTex = new BABYLON.Texture(mediaStore.getUrl(file), scene);
                diffuseTex.uScale = 20;
                diffuseTex.vScale = 20;
                mat.diffuseTexture = diffuseTex;
                // mat.diffuseColor = new BABYLON.Color3(1, 1, 1);

                // const emissiveTex = new BABYLON.Texture(mediaStore.getUrl(file), scene);
                // emissiveTex.uScale = 20;
                // emissiveTex.vScale = 20;
                // mat.emissiveTexture = emissiveTex;
            }

            if (Util.basename(file, false).endsWith('_s')) {
                const specularTex = new BABYLON.Texture(mediaStore.getUrl(file), scene);
                specularTex.uScale = 20;
                specularTex.vScale = 20;
                mat.specularTexture = specularTex;
            }

            if (Util.basename(file, false).endsWith('_n')) {
                const bumpTex = new BABYLON.Texture(mediaStore.getUrl(file), scene);
                bumpTex.uScale = 20;
                bumpTex.vScale = 20;
                mat.bumpTexture = bumpTex;
            }
        }

        mat.specularColor = mat.specularTexture ? BABYLON.Color3.White() : BABYLON.Color3.Black();
        // mat.glossiness = 0.3;
    }

    public getSkyboxRotation(): BABYLON.Quaternion {
        return BABYLON.Quaternion.Identity();
    }

    public dispose() {
        this.root.dispose();
        this.subscriptions.dispose();
    }
}