import * as BABYLON from '@babylonjs/core';
import InteractableNode from './interactableNode.js';
import { nodeFactories } from './node.js'
import { ColorProperty, OptionProperty, RangeProperty, StringProperty } from "./property.js";
import { fontFamilies } from '../services/fontManager.js';

export default class TextNode extends InteractableNode {
    public text = new StringProperty('', '/docs?p=text');
    public font = new OptionProperty(fontFamilies, 0, false, 'See <a href="https://fonts.google.com/">Google Fonts</a>', '/docs?p=text');
    public color = new ColorProperty(BABYLON.Color3.White(), '/docs?p=text');
    public size = new RangeProperty(0.01, 100, 1, false, '/docs?p=text');

    public scaleBy(scale: number) {
        this.size.value.set(this.size.get() * scale);
    }

    public get type() {
        return "text";
    }

    public get inspectorProperties() { return ['text', 'font', 'color', 'size', ...super.inspectorProperties]; }
}

nodeFactories.set('text', () => new TextNode());