import * as BABYLON from '@babylonjs/core';
import InteractableNode from './interactableNode.js';
import { nodeFactories } from './node.js'
import { RangeProperty } from "./property.js";
import { ColorProperty } from '../models/property.js';

export default class RectNode extends InteractableNode {
    public width = new RangeProperty(0.01, 100, 1, false, '/docs?p=rects');
    public height = new RangeProperty(0.01, 100, 1, false, '/docs?p=rects');
    public backgroundColor = new ColorProperty(new BABYLON.Color3(0.5, 0.5, 0.5), '/docs?p=rects');
    public borderColor = new ColorProperty(new BABYLON.Color3(0.2, 0.2, 0.2), '/docs?p=rects');
    public borderRadius = new RangeProperty(0, 1, 0.04, false, '/docs?p=rects');
    public borderThickness = new RangeProperty(0, 1, 0.01, false, '/docs?p=rects');

    public scaleBy(scale: number) {
        this.width.value.set(this.width.get() * scale);
        this.height.value.set(this.height.get() * scale);
    }

    public get type() {
        return "rect";
    }

    public get inspectorProperties() { return ['width', 'height', 'backgroundColor', 'borderColor', 'borderRadius', 'borderThickness', ...super.inspectorProperties]; }
}

nodeFactories.set('rect', () => new RectNode());