import ObjectApi from './objectApi.js';
import * as BABYLON from '@babylonjs/core';
import type TextNode from '../models/textNode.js';

/** Represents a text object in the scene. */
export default class TextApi extends ObjectApi {
    constructor(private textNode: TextNode) {
        super(textNode);
    }

    /** Returns the displayed text string */
    public getText(): string {
        return this.textNode.text.get();
    }

    /** Sets the displayed text string */
    public setText(text: string) {
        this.textNode.text.set(text);
    }

    /** Returns the font of this text. */
    public getFont(): string {
        return this.textNode.font.getSelected();
    }

    /** Sets the font of this text. */
    public setFont(value: string) {
        const idx = this.textNode.font.options.indexOf(value);
        if (idx < 0) {
            throw new Error("Could not find font: " + value);
        }

        this.textNode.font.value.set(idx);
    }

    /** Returns the color of this text in hex format. */
    public getColor(): string {
        return this.textNode.color.get().toHexString();
    }

    /** Sets the color of this text in hex format. */
    public setColor(color: string) {
        this.textNode.color.set(BABYLON.Color3.FromHexString(color));
    }

    /** Returns the size of this text. */
    public getSize(): number {
        return this.textNode.size.get();
    }

    /** Sets the size of this text. */
    public setSize(size: number) {
        this.textNode.size.set(size);
    }
}