import { writable, get } from "svelte/store";
import * as Util from '../util/util.js'
import insights from "./insights.js";

export default class TransitionManager {
    public inTransition = writable(false);
    private runPromise: Promise<void> = Promise.resolve();
    private transitionOutCallbacks: Array<() => Promise<void>> = [];
    private beforeTransitionInCallbacks: Array<() => Promise<void>> = [];
    private transitionInCallbacks: Array<() => Promise<void>> = [];
    private afterTransitionInCallbacks: Array<() => Promise<void>> = [];
    private transitionQueue: Array<() => void> = [];
    private waitForPromises: Array<Promise<void>> = [];
    private everTransitioned = false;

    public enqueueTransition(func: () => void) {
        this.transitionQueue.unshift(func);
        if (!get(this.inTransition)) {
            this.runPromise = this.run();
        }
    }

    private async run() {
        this.inTransition.set(true);

        let firstTransition = !this.everTransitioned;
        if (this.everTransitioned) {
            await Promise.all(this.transitionOutCallbacks.map(f => f()));
            await Promise.all(this.waitForPromises.splice(0));
        }

        this.everTransitioned = true;

        while (this.transitionQueue.length > 0) {
            insights.startTrackEvent("SceneLoad");
            await Util.delay(100);
            this.transitionQueue.pop()();
            await Util.delay(100);
            await Promise.all(this.waitForPromises.splice(0));
            insights.stopTrackEvent("SceneLoad", { firstScene: firstTransition });
        }

        await Promise.all(this.beforeTransitionInCallbacks.map(f => f()));
        await Promise.all(this.waitForPromises.splice(0));

        await Promise.all(this.transitionInCallbacks.map(f => f()));
        await Promise.all(this.waitForPromises.splice(0));

        await Promise.all(this.afterTransitionInCallbacks.map(f => f()));
        await Promise.all(this.waitForPromises.splice(0));

        if (this.transitionQueue.length > 0) {
            this.run();
        } else {
            this.inTransition.set(false);
        }
    }

    public registerTransitionOut(func: () => Promise<void>): () => void {
        this.transitionOutCallbacks.push(func);
        return () => Util.remove(this.transitionOutCallbacks, func);
    }

    public registerBeforeTransitionIn(func: () => Promise<void>): () => void {
        this.beforeTransitionInCallbacks.push(func);
        return () => Util.remove(this.beforeTransitionInCallbacks, func);
    }

    public registerTransitionIn(func: () => Promise<void>): () => void {
        this.transitionInCallbacks.push(func);
        return () => Util.remove(this.transitionInCallbacks, func);
    }

    public registerAfterTransitionIn(func: () => Promise<void>): () => void {
        this.afterTransitionInCallbacks.push(func);
        return () => Util.remove(this.afterTransitionInCallbacks, func);
    }

    private async waitForAllPromises() {
        while (this.waitForAllPromises.length > 0) {
            await Promise.all(this.waitForPromises.splice(0))
        }
    }

    public async waitForIdle() {
        await this.runPromise;
        await this.waitForAllPromises();
    }

    public waitForPromise(promise: Promise<void>) {
        this.waitForPromises.push(promise);
    }

    public waitForCallback() {
        let cb: () => void;
        this.waitForPromise(new Promise<void>(resolve => cb = resolve));
        return cb;
    }
}