import { nodeFactories } from './node.js'
import ObjectNode from './objectNode.js';

export default class EmptyNode extends ObjectNode {
    private isEmpty = true; // Used to make instanceof work, can remove if we add some properties.

    public scaleBy(scale: number) {}

    public get type() {
        return "empty";
    }

    public get inspectorProperties() { return [...super.inspectorProperties]; }
}

nodeFactories.set('empty', () => new EmptyNode());