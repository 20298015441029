import { Readable, writable } from 'svelte/store';

export enum ModalType {
    None,
    Project,
    DeleteProject,
    PublishProject,
    Model,
    Image,
    InsertImage,
    Sound,
    FatalError,
    Upload,
    Feedback,
    Upgrade,
    Script,
    ViewInVR,
    Share,
    SketchfabLogin,
    SketchfabMigrate,
    Attributions,
    Splash
}

class ModalManager {
    public modal = writable<ModalType>(ModalType.None);

    private stack: ModalType[] = [];
    private argsStack: any[][] = [];
    private resolves: ((data: any) => void)[] = [];

    public async push(type: ModalType, ...args: any[]) {
        this.stack.push(type);
        this.argsStack.push(args);
        this.modal.set(type);
        return new Promise<any>(resolve => this.resolves.push(resolve));
    }

    public pop(data?: any) {
        if (this.stack.length === 0) {
            return;
        }

        this.stack.pop();
        this.argsStack.pop();
        this.resolves.pop()(data);
        if (this.stack.length > 0) {
            this.modal.set(this.stack[this.stack.length - 1]);
        } else {
            this.modal.set(ModalType.None);
        }
    }

    public getArgs() {
        return this.argsStack[this.argsStack.length - 1];
    }
}

const modalManager = new ModalManager();
export const modal: Readable<ModalType> = modalManager.modal;
export default modalManager;