import type * as BABYLON from '@babylonjs/core';
import { get, Writable, writable, derived } from 'svelte/store';
import { v4 as uuidv4 } from 'uuid';
import type NodeBehavior from '../components/nodeBehavior.js'
import { StringProperty, Vector3Property, QuaternionProperty, BoolProperty } from './property.js';
import { serializeProperties, deserializeProperties, Serializable } from '../util/serializable.js';
import { array } from '../util/array.js';
import { project } from '../services/app.js';

export enum NodeFunction {
    PlaySoundFromBeginning,
    StopSound,
    ResumeSound,
    GoToWaypoint,
}

export default abstract class Node implements Serializable {
    public id = new StringProperty(uuidv4());
    public name = new StringProperty('Node');
    public enabled = new BoolProperty(true);
    public position = Vector3Property.Unset();
    public rotation = QuaternionProperty.Zero();
    public scale = Vector3Property.One();
    public parentId = new StringProperty();
    public childIds = array<string>();
    public collapsed = new BoolProperty(false);

    public meshToHighlight: Writable<BABYLON.TransformNode> = writable(null);
    public functions = new Map<NodeFunction, (args?: any) => void>();

    public transformNode = writable<BABYLON.TransformNode>(null);
    public behavior = writable<NodeBehavior>(null);

    public loadedPromise: Promise<void>;
    private loadedResolve: () => void;
    public loaded = writable(false);

    public parent = derived(this.parentId.value, (pid) => get(project).getNode(pid));

    public animationGroups = array<BABYLON.AnimationGroup>();

    constructor() {
        this.loadedPromise = new Promise<void>(resolve => this.loadedResolve = resolve);
    }

    public setLoaded() {
        if (!get(this.loaded)) {
            this.loaded.set(true);
            this.loadedResolve();
        }
    }

    public serialize(): any {
        const data = serializeProperties(this);
        data.childIds = this.childIds.get().slice();
        data.type = this.type;
        return data;
    }

    public deserialize(data: any) {
        deserializeProperties(this, data);
        this.childIds.set(data.childIds || []);
    }

    public callFunction(func: NodeFunction, args?: any) {
        const cb = this.functions.get(func);
        if (cb) {
            cb(args);
        } else {
            console.log("MISSING NODE FUNCTION: " + func + " name: " + this.name.get() + " id: " + this.id.get());
        }
    }

    public getParent(): Node {
        return get(this.parent);
    }

    public getChildren(recurse = false) {
        const children: Node[] = [];
        for (const childId of this.childIds.get()) {
            const child = get(project).getNode(childId);
            children.push(child);
            if (recurse) {
                children.push(...child.getChildren(true))
            }
        }

        return children;
    }

    public getChild(index: number) {
        return get(project).getNode(this.childIds.at(index));
    }

    public getChildIndex() {
        return this.getParent()?.childIds.indexOf(this.id.get()) ?? -1;
    }

    public abstract scaleBy(scale: number): void;

    public abstract get type(): string;

    public get inspectorProperties() { return ['enabled', 'position', 'rotation']; }

    public getWorldPosition() {
        return get(this.transformNode)?.absolutePosition;
    }

    public getWorldRotation() {
        return get(this.transformNode)?.absoluteRotationQuaternion;
    }

    public getWorldScale() {
        return get(this.transformNode)?.absoluteScaling;
    }

    public setWorldPosition(worldPosition: BABYLON.Vector3) {
        if (worldPosition) {
            get(this.transformNode).setAbsolutePosition(worldPosition);
            this.position.set(get(this.transformNode).position);
        }
    }

    public setWorldRotation(worldRotation: BABYLON.Quaternion) {
        if (worldRotation) {
            const parent = get(this.transformNode).parent;
            get(this.transformNode).setParent(null);
            get(this.transformNode).rotationQuaternion = worldRotation;
            get(this.transformNode).setParent(parent);
            this.rotation.set(get(this.transformNode).rotationQuaternion);
        }
    }

    public setWorldScale(worldScale: BABYLON.Vector3) {
        if (worldScale) {
            const parent = get(this.transformNode).parent;
            get(this.transformNode).setParent(null);
            get(this.transformNode).scaling = worldScale;
            get(this.transformNode).setParent(parent);
            this.scale.set(get(this.transformNode).scaling);
        }
    }
}

export const nodeFactories = new Map<string, () => Node>();