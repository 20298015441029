import Node, { nodeFactories } from './node.js'

export default class WaypointNode extends Node {
    private isWaypoint = true; // Used to make instanceof work, can remove if we add some properties.

    public scaleBy(scale: number) {}

    public get type() {
        return "waypoint";
    }

    public get inspectorProperties() { return [...super.inspectorProperties]; }
}

nodeFactories.set('waypoint', () => new WaypointNode());