<script lang="ts">
    import Modal from './modal.svelte'
    import modalManager, { modal, ModalType } from '../services/modalManager.js'
    import user from '../services/user.js'
    import insights from '../services/insights';

    let fatalError = '';

    $: if (fatalError) {
        modalManager.push(ModalType.FatalError);
    }

    $: closed = $modal !== ModalType.FatalError;

    function onRefresh() {
        window.location.reload();
    }

    function handleError(message: string) {
        if (message === "Error: jwt expired") {
            user.signOut();
        }

        const knownErrors = {
            "Error: jwt expired": "Login session expired. Please log in again.",
            "Error: jwt malformed": "You must be signed in to view this page.",
            "Error: jwt must be provided": "You must be signed in to view this page.",
            'The play method is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.':
                'Sounds are disabled by your browser. We\'re working on fixing this, but for now you need to manually enable sounds on this page.',
            'The specified blob does not exist': 'Project not found. Maybe you need to log in?'
        } as any;

        for (const [k, v] of Object.entries(knownErrors)) {
            if (message.startsWith(k)) {
                return v as string;
            }
        }

        return message;
    }

    window.addEventListener('error', (evt) => {
        if (evt.message === 'Script error.' || evt.message === 'ErrorEvent: Script error.') {
            // This is a CORS error, which may be caused by an iframe or something. Ignore.
            return;
        }

        console.log(evt);
        insights.trackException(evt.error);
        fatalError = handleError(evt.message || 'Unknown Error');
    });

    window.addEventListener('unhandledrejection', (evt) => {
        console.log(evt);
        if (evt.reason instanceof Error) {
            insights.trackException(evt.reason);
            fatalError = handleError(evt.reason.message || 'Unknown Error');
        } else {
            insights.trackException(new Error(evt.reason));
            fatalError = handleError(evt.reason || 'Unknown Error');
        }
    });

    $: redir = encodeURIComponent(window.location.pathname + window.location.search);
</script>

<Modal {closed} canClose={false}>
    <h2>Something went wrong</h2>
    <div class="message">{fatalError}</div>
    <button class="primary-button" on:click={onRefresh}>Refresh Page</button>
    {#if !user.signedIn}
        <a href="/login?redir={redir}" class="primary-button">Login</a>
    {/if}
    <a href="mailto:support@virtualmaker.net" class="primary-button">Report an Issue</a>
    <a class="primary-button" href="/">Return Home</a>
</Modal>

<style>
    h2 {
        color: red;
    }

    button {
        font-size: 16px;
        margin: 10px;
    }

    a {
        font-size: 16px;
        margin: 10px;
    }

    .message {
        margin-top: 10px;
        margin-bottom: 10px;
    }
</style>
