import ObjectApi from './objectApi.js';
import * as BABYLON from '@babylonjs/core';
import type BlockNode from '../models/blockNode.js';
import { get } from 'svelte/store';
import * as Util from '../util/util.js';

/** Represents a block object in the scene. */
export default class BlockApi extends ObjectApi {
    private _collideCallbacks: (() => void)[] = [];

    constructor(private blockNode: BlockNode) {
        super(blockNode);
    }

    /** Returns the material of this block. */
    public getMaterial(): string {
        return this.blockNode.material.getSelected();
    }

    /** Sets the material of this block. Example: 'plastic', 'bricks', 'wood', 'concrete', etc. */
    public setMaterial(material: string) {
        this.blockNode.material.setSelected(material);
    }

    /** Returns the color of this block in hex format. */
    public getColor(): string {
        return this.blockNode.color.get().toHexString();
    }

    /** Sets the color of this block in hex format. */
    public setColor(color: string) {
        this.blockNode.color.set(BABYLON.Color3.FromHexString(color));
    }

    /** Returns the width of this block. */
    public getWidth(): number {
        return this.blockNode.width.get();
    }

    /** Sets the width of this block. */
    public setWidth(width: number) {
        this.blockNode.width.set(width);
    }

    /** Returns the height of this block. */
    public getHeight(): number {
        return this.blockNode.height.get();
    }

    /** Sets the height of this block. */
    public setHeight(height: number) {
        this.blockNode.height.set(height);
    }

    /** Returns the depth of this block. */
    public getDepth(): number {
        return this.blockNode.depth.get();
    }

    /** Sets the depth of this block. */
    public setDepth(depth: number) {
        this.blockNode.depth.set(depth);
    }

    /** Get the physics type of this object. */
    public getPhysics(): string {
        return this.blockNode.physics.getSelected();
    }

    /** Set the physics type of this object. Options: 'none', 'dynamic', 'static' */
    public setPhysics(physics: string) {
        this.blockNode.physics.setSelected(physics);
    }

    /** Get the physics restitution of this object. */
    public getRestitution(): number {
        return this.blockNode.restitution.get();
    }

    /** Set the physics shape of this object. Options: 'none', 'dynamic', 'static' */
    public setRestitution(restitution: number) {
        this.blockNode.restitution.set(restitution);
    }

    /** Get the physics friction of this object. */
    public getFriction(): number {
        return this.blockNode.friction.get();
    }

    /** Set the physics shape of this object. Options: 'none', 'dynamic', 'static' */
    public setFriction(friction: number) {
        this.blockNode.friction.set(friction);
    }

    /** Get the physics mass of this object. */
    public getMass(): number {
        return this.blockNode.mass.get();
    }

    /** Set the physics shape of this object. Options: 'none', 'dynamic', 'static' */
    public setMass(mass: number) {
        this.blockNode.mass.set(mass);
    }

    /** Registers a callback to invoke when the model collides with another model or the environment. Requires physics to be enabled. Invoke the returned callback to unregister. */
    public onCollide(callback: () => void): () => void {
        const imposter = (get(this.node.transformNode) as BABYLON.AbstractMesh).physicsImpostor;
        if (imposter && !imposter.onCollideEvent) {
            imposter.onCollideEvent = () => {
                this._collideCallbacks.forEach(cb => cb());
            }
        }

        this._collideCallbacks.push(callback);
        return () => Util.remove(this._collideCallbacks, callback);
    }
}