import InteractableNode from './interactableNode.js';
import { nodeFactories } from './node.js'
import { ImageProperty, RangeProperty } from "./property.js";

export default class ImageNode extends InteractableNode {
    public image = new ImageProperty('/docs?p=images');
    public width = new RangeProperty(0.01, 100, 0.6, false, '/docs?p=images');

    public scaleBy(scale: number) {
        this.width.value.set(this.width.get() * scale);
    }

    public get type() {
        return "image";
    }

    public get inspectorProperties() { return ['width', ...super.inspectorProperties]; }
}

nodeFactories.set('image', () => new ImageNode());