<script lang="ts">
    import { onDestroy } from 'svelte';

    export let text = '';
    export let image = '';
    export let hoverColor = 'inherit';
    export let openColor = 'inherit';
    export let rightSide = false;
    export let padding = '0px';
    export let open = false;

    let hover = false;
    let button: HTMLButtonElement;
    let dropdown: HTMLDivElement;

    $: bgColor = open ? openColor : hover ? hoverColor : 'inherit';

    const onMouseUp = (e: MouseEvent) => {
        if (
            e.target !== button &&
            !button.contains(e.target as Node) &&
            (!dropdown?.contains(e.target as Node) || e.target instanceof HTMLButtonElement)
        ) {
            setTimeout(() => (open = false), 0);
        }
    };

    window.addEventListener('mouseup', onMouseUp);

    onDestroy(() => {
        window.removeEventListener('mouseup', onMouseUp);
    });

    $: align = rightSide ? 'right' : 'left';
</script>

<div class="root">
    <button
        bind:this={button}
        style="background-color: {bgColor}; text-align: {align}; padding-left: {padding}; padding-right: {padding};"
        on:mouseenter={() => (hover = true)}
        on:mouseleave={() => (hover = false)}
        on:click={() => (open = !open)}>
        {#if text}
            {text}<span class="arrow">▼</span>
        {:else if image}
            <img src={image} width="32px" alt="Menu" />
        {:else}
            None
        {/if}
    </button>

    {#if open}
        <div class="dropdown" bind:this={dropdown} style="{align}: 0;">
            <slot />
        </div>
    {/if}
</div>

<style>
    .root {
        position: relative;
        height: 100%;
    }

    button {
        height: 100%;
        background-color: transparent;
        color: inherit;
        border: none;
        cursor: pointer;
        font-size: inherit;
    }

    button:active {
        background-color: transparent;
    }

    .dropdown {
        position: absolute;
        top: 100%;
        display: inline-flex;
        flex-flow: column;
        min-width: 100%;
        z-index: 100;
        overflow-y: auto;
        overflow-x: hidden;
        text-overflow: ellipsis;
        max-height: 400px;
    }

    .arrow {
        font-size: 8px;
        margin-left: 10px;
    }
</style>
