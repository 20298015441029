import type * as BABYLON from '@babylonjs/core';
import type ModelNode from '../models/modelNode.js';
import { get } from 'svelte/store';
import ObjectApi from './objectApi.js';
import api from './api.js';
import type MaterialApi from './materialApi.js';
import * as Util from '../util/util.js';
import { AnimationType, FromModelAnimation } from '../models/animation.js';

/** Represents a model object in the scene. */
export default class ModelApi extends ObjectApi {
    private _collideCallbacks: (() => void)[] = [];

    constructor(private modelNode: ModelNode) {
        super(modelNode);
    }

    private getBabylonMeshes() {
        return get(this.modelNode.transformNode).getChildMeshes(false);
    }

    private getBabylonMaterials() {
        return this.getBabylonMeshes().map(m => m.material).filter(m => m);
    }

    /** Retrieves a named Material from this model. */
    public getMaterial(name: string): MaterialApi {
        const mat = this.getBabylonMaterials().find(m => m.name === name);
        if (mat) {
            return api.get(mat) as MaterialApi;
        }

        return null;
    }

    /** Returns the size of this model. */
    public getSize(): number {
        return this.modelNode.size.get();
    }

    /** Sets the size of this model. */
    public setSize(size: number) {
        this.modelNode.size.set(size);
    }

    /** Returns whether this model will cast shadows. */
    public getCastShadows(): boolean {
        return this.modelNode.castShadows.get();
    }

    /** Sets whether this model will cast shadows. */
    public setCastShadows(value: boolean) {
        this.modelNode.castShadows.set(value);
    }

    /* DEPRECATED */
    public playAnimation(name: string, loop = false) {
        this.playAnimationFromModel(name, loop);
    }

    /** Plays a named animation on this model. */
    public playAnimationFromModel(name: string, loop = false) {
        this.stopAnimations();
        this.modelNode.animation.animationType.setSelected(AnimationType.FromModel);
        const anim = this.modelNode.animation.get() as FromModelAnimation;
        anim.animation.animation.set(name);
        anim.loop.set(loop);
        setTimeout(() => {
            anim.start(this.modelNode);
        }, 0);
    }

    /** Get the physics type of this object. */
    public getPhysics(): string {
        return this.modelNode.physics.getSelected();
    }

    /** Set the physics type of this object. Options: 'none', 'dynamic', 'static' */
    public setPhysics(physics: string) {
        this.modelNode.physics.setSelected(physics);
    }

    /** Get the physics shape of this object. */
    public getPhysicsShape(): string {
        return this.modelNode.physicsShape.getSelected();
    }

    /** Set the physics shape of this object. Options: 'sphere', 'box' */
    public setPhysicsShape(physicsShape: string) {
        this.modelNode.physicsShape.setSelected(physicsShape);
    }

    /** Get the physics restitution of this object. */
    public getRestitution(): number {
        return this.modelNode.restitution.get();
    }

    /** Set the physics restitution of this object. */
    public setRestitution(restitution: number) {
        this.modelNode.restitution.set(restitution);
    }

    /** Get the physics friction of this object. */
    public getFriction(): number {
        return this.modelNode.friction.get();
    }

    /** Set the physics friction of this object. */
    public setFriction(friction: number) {
        this.modelNode.friction.set(friction);
    }

    /** Get the physics mass of this object. */
    public getMass(): number {
        return this.modelNode.mass.get();
    }

    /** Set the physics mass of this object. */
    public setMass(mass: number) {
        this.modelNode.mass.set(mass);
    }

    /** Registers a callback to invoke when the model collides with another model or the environment. Requires physics to be enabled. Invoke the returned callback to unregister. */
    public onCollide(callback: () => void): () => void {
        const imposter = (get(this.node.transformNode) as BABYLON.AbstractMesh).physicsImpostor;
        if (imposter && !imposter.onCollideEvent) {
            imposter.onCollideEvent = () => {
                this._collideCallbacks.forEach(cb => cb());
            }
        }

        this._collideCallbacks.push(callback);
        return () => Util.remove(this._collideCallbacks, callback);
    }
}